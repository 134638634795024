import React from "react";
import buildErrorSummary from "components/ErrorSummary";
import axios from "axios";
import DataTable from "components/DataTable";
import { Box } from "components/admin-lte";
import PageLayout from "components/PageLayout";
import { UserContext } from "lib/user";
import {
  Row,
  Col,
  FormGroup,
  ButtonGroup,
  ToggleButton,
  ControlLabel,
  ButtonToolbar,
  Alert,
  FormControl, Button
} from "react-bootstrap";
import { eventTypesList } from "./EventsList";
import { initTrackingProvidersList } from "./TrackingProviders";
import moment from "moment-timezone";
import ReactDOMServer from "react-dom/server";
const ADJUST_ID = "56001d06000002";
const APPSFLYER_ID = "56001d06000007";
const BRANCH_ID = "56001d06000008";
const TRAFFICGUARD_ID = "56001d06000009";
//const CLICKURL_MAX_LENGTH = 1000;
//var url = require('url');
var _ = require('lodash');

const PARAM_VALUE_VALIDATION_MSG = "Parameter values are not allowed to contain these values '://', '?' or '='";

function renderTrackingUrl(d, t, r, m) {
  
    var retVal = [];
    if(d){
      for (const key in d) {
        if(d[key]!==null && d[key].length>0 && d[key]!=="null" ){
          retVal.push(key+" = "+d[key]);
        }
      }
    }
    if(t === 'display'){
      var retVal2 = retVal.splice(0, 2);
      return (d&&`<div>${retVal2.join('<br/>')}</div><div class="hidden-cell-content">${retVal.join('<br/>')}</div>`);
    }
    return (d&&`${retVal.join('<br/>')}`);
}

function renderViewMoreClick(d, t, r, m) {
  if (t === "display") {
    if(r.clickurl && Object.keys(r.clickurl).length>0)
    {
      var clickurl = r.clickurl;
      var filteredKeys = (Object.keys(clickurl)).filter((x,idx,arr) => clickurl[x] !== null);
      if (filteredKeys.length > 2) {
        return viewMoreButtonHtml();
      }
    }
    return "";
  }
  return d;
}

function renderViewMoreImpression(d, t, r, m) {
  if (t === "display") {
    if(r.impressionurl && Object.keys(r.impressionurl).length>0)
    {
      var impressionurl = r.impressionurl;
      var filteredKeys = (Object.keys(impressionurl)).filter((x,idx,arr) => impressionurl[x] !== null);
      if (filteredKeys.length > 2) {
        return viewMoreButtonHtml();
      }
    }
    return "";
  }
  return d;
}

function renderDateTime(d, t, r, m) {
  return t === "display" && d ? moment.utc(d).format("YYYY-MM-DD HH:mm:ss") : d;
}

function renderUserName(d, t, r, m) {
  if (t === "display" || t === "filter" || t === "sort") {
    return m.settings.json.names && m.settings.json.names[d] ? m.settings.json.names[d] : d;
  }
  return d;
}

function viewMoreButtonHtml(){
  return (ReactDOMServer.renderToStaticMarkup(
      <button type='button' className='btn btn-default btn-small'>
        <span className={"viewLessText"}>View Less</span> <span className={"viewMoreText"}>View More</span>
      </button>
    )
  );
}

export default class TrackingLinkComposer extends React.Component {
  static title = "Tracking Link Composer";
  tableRef = React.createRef();
  
  constructor(props) { 
    super(props);
    this.state = {
      campaignId: null,
      trackingProvider: initTrackingProvidersList()[0].id,
      Passthrough: false,
      Clientside: false,
      Probabilistic: false,
      Reengagement: false,
      checkboxClicked: false,
      afViewthroughLookbackName: 'af_viewthrough_lookback',
      afViewthroughLookback: '',
      afViewthroughLookbackRemoved: false,
      clickUrl: null,
      impressionUrl: null,
      hasClickUrl: false,
      hasImpressionUrl: false,
      showParameterSettings: false,
      showEventMapping: false,
      isPIE: false,
      parameterSettings:[{
        paramProperty: '',
        paramValue: ''
      }],
      impUrlParameterSettings:[{
        paramProperty: '',
        paramValue: ''
      }],
      campaignBiddingModels: null,
      providerToken: null,
      eventMappings: null,
      selectedEventType: '',
      trackingProvidersList: [],
      error: null,
      busy: false,
      response: null,
      msg: null,
      unlockParametersPassword: null,
      campaignName: null,
      urlFieldsHidden: false,
      highlightedRowClickUrl: null,
      highlightedRowImpUrl: null,
      highlightedParamValueImpUrl: false,
      isTheSame: true,
      formErrors: {},
      formErrorsImp: {},
      formIsValid: null,
      showAdd: false,
      showEditDataFor: null,
      showTable: true,
      showTrackingLinkForm: false,
    };
    //this.tableRef = React.createRef();
    this.myRef = React.createRef();
    this.topRef = React.createRef();
    this.formRef = React.createRef();
  }
  componentDidMount(){
    //this.getAutoPauseOffers();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.checkboxClicked===false && this.state.checkboxClicked===true){
      this.executeScroll();
      this.setState({checkboxClicked:false});
    }
  }

  render() {
    const errorSummary = buildErrorSummary(this.state.error);
    const msgSummary = this.state.msg;
    var formErrors = this.state.formErrors;
    var formErrorsImp = this.state.formErrorsImp;
    var afViewthroughLookbackValid = this.isParamValueValid(this.state.afViewthroughLookback);
    var showTable = this.state.showTable;
    
    var isIOS = this.state.response&&this.state.response.osPlatform===1?true:false;
    
    var trackingProviderOptions = initTrackingProvidersList().map((item,idx)=>{
      return (<option value={item.id} key={"trackingProviderOption_key_"+item.id}>{item.description}</option>);
    })
    
    var campaignBiddingModels = this.state.campaignBiddingModels || [];
    var eventTypeOptions = [<option value={"no-selection"} key="eventTypeOption_key_00">Select an event..</option>];
    eventTypesList().forEach((eventType,idx)=>{
      if(campaignBiddingModels.length){
        campaignBiddingModels.forEach((model,id)=>{
          if(parseInt(model.type) === eventType.id){
            eventTypeOptions.push(<option value={model.modelId} key={"eventTypeOption_key_" + eventType.id+"_"+model.modelId}>{eventType.name} ({model.modelId})</option>);
          }
        });
      }
    })
    if(eventTypeOptions.length===1){
      eventTypeOptions[0]=(<option value="" disabled key={"eventTypeOption_key_0"}>No events found..</option>);
    }
    
    var trackingProvider = this.state.trackingProvider||'';
    var appsflyerSelected = trackingProvider === APPSFLYER_ID;
    var trafficguardSelected = trackingProvider === TRAFFICGUARD_ID;
    var noPieEvents = (!this.state.campaignBiddingModels || this.state.campaignBiddingModels.length < 1) ? true : false;
    var showAfViewthroughLookback = ((appsflyerSelected ) && !this.state.Reengagement && this.state.hasImpressionUrl && !this.state.afViewthroughLookbackRemoved) ? true : false;
    var showImpressionUrlSettings = this.state.hasImpressionUrl;
    
    return (
      <PageLayout
        breadcrumbs={["Tools"]}
        title={TrackingLinkComposer.title}
        description={""}
      >
        <>
          <div ref={this.topRef}></div>
          {errorSummary && (
            <Alert
              bsStyle="danger"
              onDismiss={() => this.setState({ error: null })}
            >
              {errorSummary}
            </Alert>
          )}
          {msgSummary && (
            <Alert
              bsStyle="success"
              onDismiss={() => this.setState({ msg: null })}
            >
              {msgSummary}
            </Alert>
          )}
          
          <div className={(!showTable?"display-none":null)}>
          
          <UserContext.Consumer>
            {user => (
              
              <Box>
                <Box.Header>
                  <DataTable.Toolbar tableRef={this.tableRef} bsSize="small">
                    <ButtonGroup>
                      <DataTable.ColumnFilter
                        tableRef={this.tableRef}
                        columnName="trackingProvider"
                        bsSize="small"
                        title="Tracking Provider"
                        defaultValue=""
                        values={[
                          ["*", ""],
                          ["Adjust", "Adjust"],
                          ["Appsflyer", "Appsflyer"],
                          ["Branch", "Branch"],
                          ["Trafficguard", "Trafficguard"],
                          ["None", "null"],
                        ]}
                      />
                      <DataTable.ColumnFilter
                        tableRef={this.tableRef}
                        columnName="linkType"
                        bsSize="small"
                        title="Link Type"
                        defaultValue=""
                        values={[
                          ["*", ""],
                          ["Passthrough", "passthrough"],
                          ["Reengagement", "reengagement"],
                          ["ClientSide", "clientSide"],
                          ["Probabilistic", "probabilistic"],
                          ["None", "null"],
                        ]}
                      />
                    </ButtonGroup>
                  </DataTable.Toolbar>
                    <ButtonGroup bsSize="small">
                      <Button style={{width:"70px"}} onClick={this.handleAddClick.bind(this,user)}>
                        Add
                      </Button>
                    </ButtonGroup>
                </Box.Header>
                <Box.Body>
                  <DataTable
                    className="table table-condensed table-striped"
                    ref={this.tableRef}
                    ajax={{
                      url: "/api/v1/tools/TrackingLinkComposerCampaigns",
                      dataSrc: "items"
                    }}
                    onUserCommand={({ commandData }) =>{
                      if (user.tokenHasExpired) {
                        window.location.replace('/login');
                        return;
                      }
                      else {
                        if(commandData && commandData.action==='edit'){
                          this.handleEditClick(commandData);
                        }
                        if(commandData && commandData.action==='viewmore'){
                          this.handleViewMoreClick(commandData);
                        }
                      }
                    }
                    }
                    columns={[
                      {
                        title: "Campaign ID",
                        data: "campaignId",
                        defaultContent: "",
                      },
                      {
                        title: "Campaign Name",
                        data: "name",
                        className: "truncate-cell-wide",
                        render: DataTable.StockRenderers.textWithTooltip()
                      },
                      {
                        title: "Tracking Provider",
                        data: "trackingProvider",
                        name: "trackingProvider",
                        width: "100px",
                        render: (d, t, r, m) => {
                          if(t === 'filter'){
                            return (d!==null&&d.length>0?d:'null');
                          }
                          return d;
                        }
                      },
                      {
                        title: "Link Type",
                        data: "linkType",
                        name: "linkType",
                        defaultContent: "",
                        render: (d, t, r, m) => {
                          var retVal = [];
                          if(d){
                            for (const key in d) {
                              if(d[key]===true){
                                retVal.push(key);
                              }
                            }
                          }
                          if(t === 'filter'){
                            if(!d||retVal.length===0)
                              return 'null';
                          }
                          return (d&&`${retVal.join(', ')}`);
                        }
                      },
                      {
                        title: "Click URL Params",
                        data: "clickurl",
                        className: "clickurl-cell overflowy-hidden",
                        defaultContent: "",
                        render: renderTrackingUrl
                      },
                      DataTable.createUserCommandColumn({
                        title: "",
                        data: "",
                        name: "viewmore",
                        className: "viewmore-click-cell",
                        width: "60px",
                        defaultContent: "",
                        visible: true,
                        render:renderViewMoreClick,
                        orderable: false,
                        searchable: false,
                        commandName: "",
                        commandData: ({ rowData, row }) => ({
                          action: 'viewmore',
                          row: row,
                          campaignId: rowData.campaignId,
                          isClick: true
                        })
                      }),
                      {
                        title: "Impression URL Params",
                        data: "impressionurl",
                        className: "impressionurl-cell overflowy-hidden",
                        defaultContent: "",
                        render: renderTrackingUrl
                      },
                      DataTable.createUserCommandColumn({
                        title: "",
                        data: "",
                        name: "viewmore",
                        className: "viewmore-impression-cell",
                        width: "60px",
                        defaultContent: "",
                        visible: true,
                        render:renderViewMoreImpression,
                        orderable: false,
                        searchable: false,
                        commandName: "",
                        commandData: ({ rowData, row }) => ({
                          action: 'viewmore',
                          row: row,
                          campaignId: rowData.campaignId,
                          isClick: false
                        })
                      }),
                      {
                        title: "Modified at",
                        data: "moderationDate",
                        className: "text-nowrap",
                        order: "desc",
                        defultContent: "",
                        render: renderDateTime,
                      },
                      {
                        title: "Modified by",
                        data: "moderatorId",
                        className: "text-nowrap",
                        defultContent: "",
                        sortable: false,
                        render: renderUserName,
                      },
                      DataTable.createUserCommandColumn({
                        title: "Actions",
                        data: null,
                        className: "text-left",
                        orderable: false,
                        width: "30px",
                        defultContent: "",
                        commandName: "",
                        commandData: ({ rowData }) => ({
                          action: 'edit',
                          campaignId: rowData.campaignId,
                          campaignName: rowData.name,
                        }),
                        render: (d, t, r, m) => ("<div><button type='button' class='btn btn-default'> Edit </button></div>")
                      })
                    ]}
                    order={[[8, "desc"]]}
                    deferRender={true}
                  />
                </Box.Body>
              </Box>
            )}
          </UserContext.Consumer>
          
          </div>
          {this.state.showTrackingLinkForm &&(
          <Box busy={this.state.busy}>
            <UserContext.Consumer>
              {user => (
                <input id="userApikey" type={"hidden"} value={user.findFirst("apikey")} />
              )}
            </UserContext.Consumer>
            
            <Box.Header>
              {this.state.showAdd?"Please insert a campaign ID, select a tracking provider and then insert a click URL.":<span>Edit click and impression links for Campaign with <strong>ID: {this.state.showEditDataFor?.campaignId} - {this.state.showEditDataFor?.campaignName}</strong></span>}
            </Box.Header>
            <Box.Body>
              <Row>
                <Col md={4}>
                  <div ref={this.formRef}></div>
                  <FormGroup>
                    <ControlLabel>Campaign ID</ControlLabel>
                    <FormControl
                      componentClass="input"
                      placeholder="Enter campaign ID"
                      maxLength={15}
                      onChange={e =>
                        this.setState({ campaignId: e.target.value })
                      }
                      value={this.state.campaignId||''}
                    />
                    <ButtonToolbar style={{marginTop:'10px'}}>
                      <Button
                        bsStyle="primary"
                        disabled={!this.state.campaignId}
                        onClick={this.handleGetCampaignUrls.bind(this, false)}
                      >Retrieve Campaign Urls</Button>
                    </ButtonToolbar>
                  </FormGroup>
                  <div className={(this.state.urlFieldsHidden?"display-none":null)}>
                    <FormGroup>
                      <label>Tracking Provider</label>
                      <select
                        className="form-control"
                        value={this.state.trackingProvider||''}
                        onChange={e => {this.setState({ trackingProvider: e.target.value });}}
                      >
                        {trackingProviderOptions}
                      </select>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Click URL</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        placeholder="Enter campaign click url"
                        rows={4}
                        maxLength={1000}
                        onChange={e =>
                          this.setState({ clickUrl: e.target.value })
                        }
                        value={this.state.clickUrl||''}
                      />
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Impression URL</ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        placeholder="Enter impression url (optional)"
                        rows={4}
                        maxLength={1000}
                        onChange={e =>
                          this.setState({ impressionUrl: e.target.value })
                        }
                        value={this.state.impressionUrl||''}
                      />
                    </FormGroup>
                  </div>
                  
                  {this.state.urlFieldsHidden &&(
                    <ButtonToolbar className="showFieldsButton" style={{marginTop:'10px'}}>
                      <Button
                        bsStyle="primary"
                        onClick={this.hideUrlFields.bind(this,false)}
                      >Show Campaign Urls</Button>
                    </ButtonToolbar>
                 )}
                
                </Col>
              </Row>
            </Box.Body>
            {!this.state.urlFieldsHidden &&(
            <Box.Footer>
              <ButtonToolbar>
                <Button
                  bsStyle="primary"
                  disabled={
                    !this.state.campaignId ||
                    !this.state.trackingProvider ||
                    !this.state.clickUrl
                  }
                  onClick={this.handleGetCampaignInfo.bind(this, false)}
                >Process Campaign Urls</Button>
                <Button
                  bsStyle="default"
                  onClick={this.handleCancelClick.bind(this, false)}
                >Cancel</Button>
              </ButtonToolbar>
            </Box.Footer>
            )}
          </Box>
          )}
        </>
        {this.state.showParameterSettings && (
          <Box busy={this.state.busy}>
            <Box.Header title={"Parameter Settings - Campaign: " + this.state.campaignName} />
            <Box.Body>
              <Row key="unlock_parameters_row">
                <Col md={2}>
                    <FormGroup>
                      <FormControl
                        componentClass="input"
                        placeholder="Unlock all parameters PIN"
                        maxLength={100}
                        disabled={false}
                        onChange={e => {
                          var state = this.state;
                          state.unlockParametersPassword = e.target.value;
                          this.setState({ unlockParametersPassword: state.unlockParametersPassword });
                        }
                        }
                      />
                    </FormGroup>
                </Col>
                <Col md={2}></Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col md={6} style={{paddingRight:"0", borderRight:"1px solid #cdcdcd"}}>
                  <Row><Col md={12}><h4 className={"bold"}>CLICK URL</h4></Col></Row>

                  {this.state.parameterSettings && (this.state.parameterSettings || []).map((pSetting, idx, array) => {
                    //Disable remove btn and edit action for mandatory parameters
                    var removeBtnDisabled = this.disallowRemoveParameter(pSetting.paramProperty);
                    
                    return(<Row key={"parameterSettingRow_key_"+idx} className={this.state.highlightedRowClickUrl===idx?"highlighted-row":null}>
                        <Col md={4}>
                          <FormGroup>
                            <FormControl
                              componentClass="input"
                              placeholder="Enter parameter"
                              maxLength={100}
                              disabled={removeBtnDisabled&&(pSetting.paramProperty!=="af_siteid")}
                              onChange={e => {
                                var state = this.state;
                                var impUrlParameterSettings = state.impUrlParameterSettings;
                                if(this.state.parameterSettings&&this.state.highlightedRowClickUrl!==null){
                                  impUrlParameterSettings.forEach((setting, idx)=>{
                                    if(setting.paramProperty === pSetting.paramProperty){
                                      setting.paramProperty = e.target.value;
                                    }
                                  });
                                }
                                state.parameterSettings[idx].paramProperty = e.target.value;
                                this.setState({ parameterSettings: state.parameterSettings, impUrlParameterSettings: impUrlParameterSettings,});
                              }}
                              value={pSetting.paramProperty}
                              onFocus={(e)=>this.setState({highlightedRowClickUrl:idx, highlightedRowImpUrl:null, highlightParamValueImpUrl:false})}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup className={(formErrors[idx]?'form-error':'')}>
                            <FormControl
                              componentClass="input"
                              placeholder="Enter value"
                              maxLength={300}
                              disabled={removeBtnDisabled&&(pSetting.paramProperty!=="af_siteid")}
                              onChange={e => {
                                var state = this.state;
                                state.parameterSettings[idx].paramValue = e.target.value;
                                var impUrlParameterSettings = state.impUrlParameterSettings;
                                
                                if(this.state.parameterSettings&&this.state.highlightedRowClickUrl!==null){
                                  impUrlParameterSettings.forEach((setting, idx)=>{
                                    if(setting.paramProperty === pSetting.paramProperty){
                                      setting.paramValue = e.target.value;
                                      
                                      /*//validate input field
                                      state.formErrorsImp[idx] = false;
                                      if(!this.isParamValueValid(e.target.value)){
                                        if(state.formErrorsImp){
                                          state.formErrorsImp[idx]=PARAM_VALUE_VALIDATION_MSG;
                                          state.formIsValid = false;
                                        }
                                      }*/
                                    }
                                  });
                                }
                                
                               /* //validate input field
                                state.formErrors[idx] = false;
                                if(!this.isParamValueValid(e.target.value)){
                                  if(state.formErrors){
                                    state.formErrors[idx]=PARAM_VALUE_VALIDATION_MSG;
                                    state.formIsValid = false;
                                  }
                                }*/
                                
                                this.setState({ 
                                  parameterSettings: state.parameterSettings, 
                                  impUrlParameterSettings: impUrlParameterSettings, 
                                  //formErrors: state.formErrors, 
                                  //formErrorsImp: state.formErrorsImp, 
                                  //formIsValid: state.formIsValid
                                },(e)=>{this.validateParamValueFields();});
                                
                              }
                              }
                              value={pSetting.paramValue}
                              onFocus={(e)=>this.setState({highlightedRowClickUrl:idx, highlightParamValueImpUrl:true, highlightedRowImpUrl:null})}
                            />
                            <Row>
                              <Col md={12} sm={8} xs={10}>
                                <div className={"error-msg"}>{formErrors[idx]||''}</div>
                              </Col>
                            </Row>
                          </FormGroup>
                          
                        </Col>
                        <Col md={4}>
                          {array.length>1 &&(
                            <Button
                              id={"id_"+idx}
                              bsStyle="danger"
                            disabled={removeBtnDisabled && pSetting.paramProperty !== "af_c_id" && removeBtnDisabled && pSetting.paramProperty !== "x_af_c_id"}
                              onClick={this.handleRemoveParameterSetting.bind(this, idx)}
                            > - Remove </Button>
                        )}
                          {array.length === idx+1 &&(
                            <Button
                              id={"id_"+idx}
                              bsStyle="primary"
                              onClick={this.handleAddParameterSetting.bind(this)}
                              style={{"marginLeft":"15px"}}
                            > + Add </Button>
                          )}
                        </Col>
                      </Row>
                    );
                  })
                  }
                  <br/>
                  
                </Col>
                {showImpressionUrlSettings && (
                <Col md={6}>
                  
                  <Row><Col md={12}><h4 className={"bold"}>IMPRESSION URL</h4></Col></Row>

                  {this.state.response && this.state.response.impressionUrl && this.state.response.impressionUrl.length>0 && 
                   this.state.impUrlParameterSettings && (this.state.impUrlParameterSettings || []).map((pSetting, idx, array) => {
                    //Disable remove btn and edit action for mandatory parameters
                    if(pSetting.paramProperty!=="af_viewthrough_lookback" && pSetting.paramProperty!==this.state.afViewthroughLookbackName){
                      var removeBtnDisabled = this.disallowRemoveParameter(pSetting.paramProperty);
                      var inputClass = this.state.parameterSettings&&this.state.highlightedRowClickUrl!==null&&this.state.parameterSettings[this.state.highlightedRowClickUrl].paramProperty===pSetting.paramProperty?"highlighted-input":null;
                      
                      return (<Row key={"parameterSettingRow_key_"+idx} className={this.state.highlightedRowImpUrl===idx?"highlighted-row":null}>
                          <Col md={4}>
                            <FormGroup>
                              <FormControl
                                className={inputClass}
                                componentClass="input"
                                placeholder="Enter parameter"
                                maxLength={100}
                                disabled={removeBtnDisabled&&(pSetting.paramProperty!=="af_siteid")}
                                onChange={e => {
                                  var state = this.state;
                                  state.impUrlParameterSettings[idx].paramProperty = e.target.value;
                                  this.setState({ impUrlParameterSettings: state.impUrlParameterSettings });
                                }
                                }
                                value={pSetting.paramProperty}
                                onFocus={(e)=>this.setState({highlightedRowClickUrl:null, highlightParamValueImpUrl:false, highlightedRowImpUrl:idx})}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup className={(formErrorsImp[idx]?'form-error':'')}>
                              <FormControl
                                className={inputClass}
                                componentClass="input"
                                placeholder="Enter value"
                                maxLength={300}
                                disabled={removeBtnDisabled&&(pSetting.paramProperty!=="af_siteid")}
                                onChange={e => {
                                  var state = this.state;
                                  state.impUrlParameterSettings[idx].paramValue = e.target.value;

                                  //validate input field
                                  /*state.formErrorsImp[idx] = false;
                                  if(!this.isParamValueValid(e.target.value)){
                                    if(state.formErrorsImp){
                                      state.formErrorsImp[idx]=PARAM_VALUE_VALIDATION_MSG;
                                      state.formIsValid = false;
                                    }
                                  }*/
                                  
                                  this.setState({ impUrlParameterSettings: state.impUrlParameterSettings/*, formErrorsImp: state.formErrorsImp, formIsValid: state.formIsValid*/} ,(e)=>{this.validateParamValueFields();});
                                }
                                }
                                value={pSetting.paramValue}
                                onFocus={(e)=>this.setState({highlightedRowClickUrl:null, highlightedRowImpUrl:idx})}
                              />
                              <Row>
                                <Col md={12} sm={8} xs={10}>
                                  <div className={"error-msg"}>{formErrorsImp[idx]||''}</div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            {array.length>1 &&(
                              <Button
                                id={"id_"+idx}
                                bsStyle="danger"
                              disabled={removeBtnDisabled && pSetting.paramProperty !== "af_c_id" && removeBtnDisabled && pSetting.paramProperty !== "x_af_c_id"}
                                onClick={this.handleRemoveImpUrlParameterSetting.bind(this, idx)}
                              > - Remove </Button>
                          )}
                            {array.length === idx+1 && !showAfViewthroughLookback &&(
                              <Button
                                id={"id_"+idx}
                                bsStyle="primary"
                                onClick={this.handleAddImpUrlParameterSetting.bind(this)}
                                style={{"marginLeft":"15px"}}
                              > + Add </Button>
                            )}
                          </Col>
                        </Row>
                      );
                    }
                    return null;
                  })
                  }
                  
                  {showAfViewthroughLookback && (
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <FormControl
                            componentClass="input"
                            placeholder={"af_viewthrough_lookback"}
                            maxLength={100}
                            disabled={this.disallowRemoveParameter("af_viewthrough_lookback")}
                            value={this.state.afViewthroughLookbackName}
                            onChange={e => {
                              var state = this.state;
                              var value = e.target.value;
                              var implnkUrl = this.editImpressionUrlParameterName([{paramProperty:this.state.afViewthroughLookbackName,paramValue:this.state.afViewthroughLookback}],value);
                              state.parameterSettings.forEach((setting, idx)=>{
                                if (setting && setting.paramProperty === "implnk$"){
                                  setting.paramValue = implnkUrl;
                                  return;
                                }
                              });
                              state.impUrlParameterSettings.forEach((setting, idx, settingsArray)=>{
                                if (setting && setting.paramProperty === "af_viewthrough_lookback"){
                                  delete settingsArray[idx];
                                  return;
                                }
                              });
                              this.setState({ afViewthroughLookbackName:value, parameterSettings:state.parameterSettings, impUrlParameterSettings:state.impUrlParameterSettings })
                            }
                            }
                          />
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className={!afViewthroughLookbackValid?'form-error':''}>
                          <FormControl
                            componentClass="input"
                            placeholder="Enter value"
                            maxLength={300}
                            onChange={e => {
                              var state = this.state;
                              var value = e.target.value;
                              var implnkUrl = this.editImpressionUrlParameter([{paramProperty:this.state.afViewthroughLookbackName,paramValue:value}]);
                              state.parameterSettings.forEach((setting, idx)=>{
                                if (setting && setting.paramProperty === "implnk$"){
                                  setting.paramValue = implnkUrl;
                                  return;
                                }
                              });
                              this.setState({ afViewthroughLookback: value, parameterSettings: state.parameterSettings} ,(e)=>{this.validateParamValueFields();});
                            }
                              
                            }
                            value={this.state.afViewthroughLookback}
                          />
                          <Row>
                            <Col md={12} sm={8} xs={10}>
                              <div className={"error-msg"}>{!afViewthroughLookbackValid?PARAM_VALUE_VALIDATION_MSG:''}</div>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                          <Button
                            id={"af_viewthrough_lookback_remove_btn"}
                            bsStyle="danger"
                            disabled={this.disallowRemoveParameter("af_viewthrough_lookback")}
                            onClick={e => {
                              var impUrlParameterSettings = this.state.impUrlParameterSettings;
                              (impUrlParameterSettings||[]).forEach((setting, idx)=>{
                                if (setting && setting.paramProperty === "af_viewthrough_lookback"){
                                  this.handleRemoveImpUrlParameterSetting(idx);
                                }
                              });
                              this.setState({afViewthroughLookbackRemoved:true, afViewthroughLookback:''});
                            }}
                          > - Remove </Button>
                          <Button
                            bsStyle="primary"
                            onClick={this.handleAddImpUrlParameterSetting.bind(this)}
                            style={{"marginLeft":"15px"}}
                          > + Add </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
                )}
              </Row>
              <Row className={"divider-row"}>
                <Col md={11}></Col>
              </Row>
              <Row>
                <Col md={6}>
                  {this.state.showEventMapping && (
                    <div className="noPaddingLeft">
                      <Box.Header title="Event Mapping" />
                      {this.state.eventMappings && (this.state.eventMappings||[]).map((pSetting,idx,array)=>{
                        var addBtnDisabled = ((this.state.campaignBiddingModels && this.state.campaignBiddingModels.length<1) || (this.state.campaignBiddingModels && this.state.campaignBiddingModels.length<=idx+1));
                        return(<Row key={"eventMappingRow_key_"+idx}>
                            <Col md={4}>
                              <FormGroup>
                                {idx===0 && (<ControlLabel>Provider Token</ControlLabel>)}
                                <FormControl
                                  componentClass="input"
                                  placeholder="Enter provider token"
                                  maxLength={100}
                                  onChange={e => {
                                    var state = this.state;
                                    state.eventMappings[idx].providerToken = e.target.value;
                                    this.setState({ eventMappings: state.eventMappings });
                                  }
                                  }
                                  value={pSetting.providerToken}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                {idx===0 && (<label>Event</label>)}
                                <select
                                  className="form-control"
                                  value={this.state.eventMappings[idx].modelId||''}
                                  onChange={e => {
                                    var state = this.state;
                                    state.eventMappings[idx].modelId = e.target.value;
                                    this.setState({eventMappings: state.eventMappings});
                                  }}
                                >
                                  {eventTypeOptions}
                                </select>

                              </FormGroup>
                            </Col>
                            <Col md={4} style={(idx===0?{marginTop:"25px"}:null)}>
                              <FormGroup>
                                {array.length>1 &&(
                                  <Button
                                    id={"id_"+idx}
                                    bsStyle="danger"
                                    onClick={this.handleRemoveEventMapping.bind(this, idx)}
                                  > - Remove </Button>
                                )}
                                {array.length === idx+1 &&(
                                  <Button
                                    id={"add_btn_id_"+idx}
                                    bsStyle="primary"
                                    disabled={addBtnDisabled}
                                    onClick={this.handleAddEventMapping.bind(this)}
                                    style={(idx===0?null:{marginLeft:"15px"})}
                                  > + Add </Button>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        );
                      })
                      }
                    </div>
                  )}
                  {this.state.showEventMapping && noPieEvents && this.state.eventMappings.length<1 && (
                    <div className="noPaddingLeft">
                      {/*<Box.Header title="Event Mapping" />*/}
                      <Row>
                        <Col md={2}>
                          <FormGroup>
                            <ControlLabel>Provider Token</ControlLabel>
                            <FormControl
                              componentClass="input"
                              placeholder="Enter provider token"
                              maxLength={100}
                              onChange={e => {
                                var state = this.state;
                                if(state.eventMappings[0]){
                                  state.eventMappings[0].providerToken = e.target.value;
                                }else{
                                  var defaultEventMapping = this.getDefaultEventMappings()[0];
                                  defaultEventMapping.providerToken = e.target.value;
                                  state.eventMappings.push(defaultEventMapping);
                                }
                                this.setState({ eventMappings: state.eventMappings });
                              }
                              }
                              value={this.state.eventMappings[0]?this.state.eventMappings[0].providerToken:''}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <label>Event</label>
                            <select
                              className="form-control"
                              value={''}
                              onChange={e => {
                                return false;
                              }}
                            >
                              {eventTypeOptions}
                            </select>

                          </FormGroup>
                        </Col>
                      </Row>
                    </div>)
                  }
                  
                </Col>
              </Row>
              <Row style={{visibility:"hidden"}}>
                <Col md={12}>
                  <div className={"form-group"}>
                    <ButtonGroup>
                      <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="secondary"
                        checked={this.state.Passthrough}
                        disabled={this.state.Probabilistic || this.state.Reengagement}
                        value="1"
                        onChange={(e) => {
                          this.setState({ Passthrough: e.currentTarget.checked });
                          this.handlePassthroughChange(e,this.state.parameterSettings, true);
                          this.handlePassthroughChange(e,this.state.impUrlParameterSettings);
                          this.validateParamValueFields();
                        }}
                        onClick={(e) => {
                          this.setState({ checkboxClicked:true });
                        }}
                      >
                        &nbsp;Passthrough
                      </ToggleButton>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={"form-group"}>
                    <ButtonGroup>
                      <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="secondary"
                        checked={this.state.Clientside}
                        disabled={this.state.Reengagement || (this.state.trackingProvider === ADJUST_ID && !this.state.hasImpressionUrl)}
                        value="1"
                        onChange={(e) => {
                          var isChecked = e.currentTarget.checked;
                          this.setState({ Clientside: isChecked});
                          this.handleClientsideChange(isChecked, this.state.parameterSettings, this.state.trackingProvider, true);
                          this.handleClientsideChange(isChecked, this.state.impUrlParameterSettings, this.state.trackingProvider);
                          this.validateParamValueFields();
                        }}
                        onClick={(e) => {
                          this.setState({ checkboxClicked:true });
                        }}
                      >
                        &nbsp;Client Side
                      </ToggleButton>
                    </ButtonGroup>
                  </div>

                </Col>
              </Row>
              {(this.state.trackingProvider !== ADJUST_ID) && (
              <Row>
                <Col md={12}>
                  <div className={"form-group"}>
                    <ButtonGroup>
                      <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="secondary"
                          checked={this.state.Reengagement}
                          disabled={this.state.Passthrough || this.state.Clientside || !this.state.isTheSame || this.state.Probabilistic}
                        value="1"
                        onChange={(e) => {
                          this.setState({ Reengagement: e.currentTarget.checked });
                          this.handleReengagementChange(e, this.state.parameterSettings, false, this.state.trackingProvider);
                          this.handleReengagementChange(e, this.state.impUrlParameterSettings, true, this.state.trackingProvider);
                          this.validateParamValueFields();
                        }}
                        onClick={(e) => {
                          this.setState({ checkboxClicked: true });
                        }}
                      >
                        &nbsp;Reengagement
                      </ToggleButton>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
              )}
              {((this.state.trackingProvider === APPSFLYER_ID && isIOS) || (this.state.trackingProvider === TRAFFICGUARD_ID && isIOS) || (this.state.trackingProvider === ADJUST_ID)) && (
              <Row>
                <Col md={12}>
                  <div className={"form-group"}>
                    <ButtonGroup>
                      <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="secondary"
                          checked={this.state.Probabilistic}
                          disabled={this.state.Passthrough || this.state.Reengagement || (this.state.trackingProvider === ADJUST_ID && !this.state.hasImpressionUrl)}
                        value="1"
                        onChange={(e) => {
                          this.setState({ Probabilistic: e.currentTarget.checked});
                          this.handleProbabilisticChange(e, this.state.parameterSettings);
                          this.handleProbabilisticChange(e, this.state.impUrlParameterSettings,true);
                          this.validateParamValueFields();
                        }}
                        onClick={(e) => {
                          this.setState({ checkboxClicked:true });
                        }}
                      >
                        &nbsp;Probabilistic
                      </ToggleButton>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
              )}
            </Box.Body>
           
            <Box.Footer>
              <ButtonToolbar>
                <Button
                  bsStyle="primary"
                  disabled={this.allowSave()}
                  onClick={this.handleSave.bind(this, false)}
                >Save Campaign Urls</Button>
                <Button
                  bsStyle="default"
                  onClick={this.handleCancelClick.bind(this, false)}
                >Cancel</Button>
              </ButtonToolbar>
            </Box.Footer>
            {!this.state.isPIE && this.state.trackingProvider === ADJUST_ID ?
              <Box.Body>
                <Row>
                  <Col md={4}>
                    <ButtonToolbar>
                      <FormGroup>
                        <ControlLabel>Event token</ControlLabel>
                        <FormControl
                          componentClass="input"
                          placeholder="Event token"
                          maxLength={15}
                          onChange={e =>
                            this.setState({ eventToken: e.target.value })
                          }
                          value={this.state.eventToken || ''}
                        />
                      </FormGroup>
                      <Button
                        bsStyle="primary"
                        disabled={!this.state.eventToken||this.state.formIsValid===false}
                        onClick={this.handleSave.bind(this, false)}
                      >Save as CPA</Button>
                      <Button
                        bsStyle="default"
                        onClick={this.handleCancelClick.bind(this, false)}
                      >Cancel</Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
                </Box.Body>
              :null
            }
          </Box>
        )}
        <div ref={this.myRef} style={{height:"0px"}}></div>
      </PageLayout>
    );
  }
  
  executeScroll(ref){
    if(ref){
      ref.current.scrollIntoView();
    }
    else {
      this.myRef.current.scrollIntoView();
    }
  }
  
  appsflyerSelectedAndHasClickUrl(checkHasNoImpUrl){
    var appsflyerSelected = this.state.trackingProvider === APPSFLYER_ID;
    var hasClickUrl = this.state.hasClickUrl;
    var hasImpressionUrl = this.state.hasImpressionUrl;
    if(checkHasNoImpUrl){
      return (appsflyerSelected && hasClickUrl && !hasImpressionUrl);
    }
    return (appsflyerSelected && hasClickUrl);
  }

  trafficguardSelectedAndHasClickUrl(checkHasNoImpUrl) {
    var trafficgurardSelected = this.state.trackingProvider === TRAFFICGUARD_ID;
    var hasClickUrl = this.state.hasClickUrl;
    var hasImpressionUrl = this.state.hasImpressionUrl;
    if (checkHasNoImpUrl) {
      return (trafficgurardSelected && hasClickUrl && !hasImpressionUrl);
    }
    return (trafficgurardSelected && hasClickUrl);
  }
  
  getAppsflyerPrefix(){
    return (this.appsflyerSelectedAndHasClickUrl()?"com":"org");
  }

  getTrafficguardPrefix() {
    return (this.trafficguardSelectedAndHasClickUrl() ? "com" : "org");
  }

  handleReengagementChange(e, settings, isParameterSettings, trackingProvider) {
    var isIOS = this.state.response && this.state.response.osPlatform === 1?true:false;
    
    if (settings && !isParameterSettings) {
      if (e.currentTarget.checked) {
        if (trackingProvider === APPSFLYER_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'af_reengagement_window' || pSetting.paramProperty === 'af_siteid' || pSetting.paramProperty === 'af_click_lookback')) {
              delete settingsArr[idx];
            }
          });
          
          settings.push({ paramProperty: 'af_siteid', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
          settings.push({ paramProperty: 'is_retargeting', paramValue: 'true' });
          settings.push({ paramProperty: 'af_reengagement_window', paramValue: '60d' });
          settings.push({ paramProperty: 'af_click_lookback', paramValue: '7d' });
        }
        if (trackingProvider === TRAFFICGUARD_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'x_af_reengagement_window' || pSetting.paramProperty === 'site_id' || pSetting.paramProperty === 'click_lookback')) {
              delete settingsArr[idx];
            }
          });

          settings.push({ paramProperty: 'site_id', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
          settings.push({ paramProperty: 'is_retargeting', paramValue: 'true' });
          settings.push({ paramProperty: 'x_af_reengagement_window', paramValue: '60d' });
          settings.push({ paramProperty: 'click_lookback', paramValue: '7d' });
        }
      }
      else {
        if (trackingProvider === APPSFLYER_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'af_reengagement_window' || pSetting.paramProperty === 'af_siteid')) {
              delete settingsArr[idx];
            }
          });
          
          settings.push({ paramProperty: 'af_siteid', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
        }
        if (trackingProvider === TRAFFICGUARD_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'x_af_reengagement_window' || pSetting.paramProperty === 'site_id')) {
              delete settingsArr[idx];
            }
          });

          settings.push({ paramProperty: 'site_id', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
        }
      }
    }

    if (settings && isParameterSettings) {
      if (e.currentTarget.checked) {
        if (trackingProvider === APPSFLYER_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'redirect' || pSetting.paramProperty === 'af_viewthrough_lookback' || pSetting.paramProperty === 'af_siteid' || pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'af_click_lookback')) {
              delete settingsArr[idx];
            }
          });
          settings.push({ paramProperty: 'is_retargeting', paramValue: 'true' });
          settings.push({ paramProperty: 'af_siteid', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
          settings.push({ paramProperty: 'redirect', paramValue: 'false' });
          settings.push({ paramProperty: 'af_click_lookback', paramValue: '7d' });
        }
        if (trackingProvider === TRAFFICGUARD_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'redirect' || pSetting.paramProperty === 'x_af_viewthrough_lookback' || pSetting.paramProperty === 'site_id' || pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'click_lookback')) {
              delete settingsArr[idx];
            }
          });
          settings.push({ paramProperty: 'is_retargeting', paramValue: 'true' });
          settings.push({ paramProperty: 'site_id', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
          settings.push({ paramProperty: 'redirect', paramValue: 'false' });
          settings.push({ paramProperty: 'click_lookback', paramValue: '7d' });
        }
      }
      else {
        if (trackingProvider === APPSFLYER_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'redirect' || pSetting.paramProperty === 'af_click_lookback' || pSetting.paramProperty === 'af_siteid' || pSetting.paramProperty === 'is_retargeting' )) {
              delete settingsArr[idx];
            }
          });
          settings.push({ paramProperty: 'af_siteid', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
          settings.push({ paramProperty: 'af_viewthrough_lookback', paramValue: '24h' });
        }
        if (trackingProvider === TRAFFICGUARD_ID) {
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting && (pSetting.paramProperty === 'redirect' || pSetting.paramProperty === 'click_lookback' || pSetting.paramProperty === 'site_id' || pSetting.paramProperty === 'is_retargeting')) {
              delete settingsArr[idx];
            }
          });
          settings.push({ paramProperty: 'site_id', paramValue: isIOS ? '{af_siteid_macro_1d200460}' : '{af_siteid_macro_1d200460}' });
          settings.push({ paramProperty: 'x_af_viewthrough_lookback', paramValue: '24h' });
        }
      }
    } 
  }
  
  handlePassthroughChange(e, settings, isParameterSettings){
    settings && (settings || []).forEach((pSetting, idx, array) => {
      if (e.currentTarget.checked) {
        if (pSetting.paramProperty === 'advertising_id' || pSetting.paramProperty === 'gps_adid' || pSetting.paramProperty === 'android_id') {
          pSetting.paramValue = '{com_gaid}'
        }
        if (pSetting.paramProperty === 'idfa') {
          pSetting.paramValue = '{com_idfa}'
        }
      }
      else {
        //var prefix = this.getAppsflyerPrefix();
        if (pSetting.paramProperty === 'advertising_id' || pSetting.paramProperty === 'gps_adid' || pSetting.paramProperty === 'android_id') {
          pSetting.paramValue = '{com_gaid}';
        }
        if (pSetting.paramProperty === 'idfa') {
          pSetting.paramValue = '{com_idfa}';
        }
      }
    });
    
    if(isParameterSettings){
      if (e.currentTarget.checked) {
        settings.push({paramProperty:'devidreq$', paramValue:1})
      } else {
        settings.forEach((pSetting,idx, settingsArr)=>{
          if(pSetting && pSetting.paramProperty==='devidreq$' && (pSetting.paramValue === 1 || pSetting.paramValue === '1')){
            delete settingsArr[idx];
          }
        });
      }
    }
  }

  handleClientsideChange(isChecked, settings, trackingProvider, isClick)
  {
    var isIOS = this.state.response && this.state.response.osPlatform === 1 ? true : false;
    var probalisticChecked = this.state.Probabilistic;
    if (isClick) {
      if (isChecked) {
        settings.forEach((pSetting, idx, settingsArr) => {
          if (pSetting && pSetting.paramProperty === 'csr$') {
            delete settingsArr[idx];
          }
          if (pSetting && pSetting.paramProperty === 'user_agent') {
            pSetting.paramProperty = 'x_af_ua';
          }
          if (pSetting && pSetting.paramProperty === 'ip_address') {
            pSetting.paramProperty = 'x_af_ip';
          }
          if (pSetting && pSetting.paramProperty === 'af_siteid') {
            if(isIOS){
              if (!isChecked) {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
              else {
                pSetting.paramValue = '{af_siteid_macro_1d200460}';
              }
            }
            else {
              if (!isChecked) {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
              else {
                pSetting.paramValue = '{af_siteid_macro_1d200460}';
              }
            }
          }
        });
        settings.push({ paramProperty: 'csr$', paramValue: 'true' });
      }
      else {
        if (trackingProvider === APPSFLYER_ID) {
          settings.push({ paramProperty: 'onehrts', paramValue: 'true' });
          settings.push({ paramProperty: 'redirect', paramValue: 'true' });
        }
        if (trackingProvider === TRAFFICGUARD_ID) {
          settings.push({ paramProperty: 'onehrts', paramValue: 'true' });
          settings.push({ paramProperty: 'redirect', paramValue: 'true' });
        }
        if (trackingProvider === ADJUST_ID) {
          settings.push({ paramProperty: 'onehrts', paramValue: 'true' });
          settings.push({ paramProperty: 's2s', paramValue: 1 });
        }
        if (trackingProvider === BRANCH_ID) {
          settings.push({ paramProperty: 'onehrts', paramValue: 'true' });
          settings.push({ paramProperty: '%24s2s', paramValue: 'true' });
        }
        settings.forEach((pSetting, idx, settingsArr) => {
          if (pSetting && pSetting.paramProperty === 'x_af_ua') {
            pSetting.paramProperty = 'user_agent';
          }
          if (pSetting && pSetting.paramProperty === 'x_af_ip') {
            pSetting.paramProperty = 'ip_address';
          }
          if (pSetting && pSetting.paramProperty === 'af_siteid') {
            if(isIOS){
              if (probalisticChecked) {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
              else {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
            }else{
              pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
            }
            
          }
        });
      }
    }
    //Impression url
    else {
      if (isChecked) {
        settings.push({ paramProperty: 'csrds', paramValue: 'true' });
        settings.forEach((pSetting, idx, settingsArr) => {
          if (pSetting && pSetting.paramProperty === 'af_siteid') {
            if (isIOS) {
              if (!isChecked) {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
              else {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
            }
            else{
              pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
            }
          }
        });
      }
      else {
        if (trackingProvider === ADJUST_ID) {
          settings.push({ paramProperty: 's2s', paramValue: 1 });
        }
        if (trackingProvider === APPSFLYER_ID) {
          if (!probalisticChecked) {
            //settings.push({ paramProperty: 'is_retargeting', paramValue: 'true' });
            //settings.push({ paramProperty: 'af_reengagement_window', paramValue: '60d' });
          }
        }
        if (trackingProvider === TRAFFICGUARD_ID) {
          if (!probalisticChecked) {
            //settings.push({ paramProperty: 'is_retargeting', paramValue: 'true' });
            //settings.push({ paramProperty: 'x_af_reengagement_window', paramValue: '60d' });
          }
        }
        if (trackingProvider === BRANCH_ID) {
          settings.push({ paramProperty: '%24s2s', paramValue: 'true' });
        }
        settings.forEach((pSetting, idx, settingsArr) => {
          if (pSetting && pSetting.paramProperty === 'af_siteid') {
            if(isIOS){
              if (probalisticChecked) {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
              else {
                pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
              }
            }
            else{
              pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
            }
            
          }
        });
      }
    }

    settings && (settings || []).forEach((pSetting, idx, array) => {
      if (isClick) {
        if (isChecked) {
          if (trackingProvider === APPSFLYER_ID) {
            if (pSetting.paramProperty === 'onehrts' || pSetting.paramProperty === 'redirect') {
              settings.forEach((pSetting, idx, settingsArr) => {
                if (pSetting && pSetting.paramProperty === 'onehrts') {
                  delete settingsArr[idx];
                }
                if (pSetting && pSetting.paramProperty === 'redirect') {
                  delete settingsArr[idx];
                }
                if (pSetting && pSetting.paramProperty === 'af_siteid') {
                  if(isIOS){
                    if (!isChecked) {
                      pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                    }
                    else {
                      pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                    }
                  }
                  else{
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                  
                }
              });
            }
          }
          if (trackingProvider === TRAFFICGUARD_ID) {
            if (pSetting.paramProperty === 'onehrts' || pSetting.paramProperty === 'redirect') {
              settings.forEach((pSetting, idx, settingsArr) => {
                if (pSetting && pSetting.paramProperty === 'onehrts') {
                  delete settingsArr[idx];
                }
                if (pSetting && pSetting.paramProperty === 'redirect') {
                  delete settingsArr[idx];
                }
                if (pSetting && pSetting.paramProperty === 'site_id') {
                  if (isIOS) {
                    if (!isChecked) {
                      pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                    }
                    else {
                      pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                    }
                  }
                  else {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }

                }
              });
            }
          }
          if (trackingProvider === ADJUST_ID) {
            if (pSetting.paramProperty === 'onehrts' || pSetting.paramProperty === 's2s' ) {
              settings.forEach((pSetting, idx, settingsArr) => {
                if (pSetting && pSetting.paramProperty === 'onehrts') {
                  delete settingsArr[idx];
                }
                if (pSetting && pSetting.paramProperty === 's2s') {
                  delete settingsArr[idx];
                }
              });
            }
          }
          if (trackingProvider === BRANCH_ID) {
            if (pSetting.paramProperty === 'onehrts' || pSetting.paramProperty === '%24s2s' ) {
              settings.forEach((pSetting, idx, settingsArr) => {
                if (pSetting && pSetting.paramProperty === 'onehrts') {
                  delete settingsArr[idx];
                }
                if (pSetting && pSetting.paramProperty === '%24s2s') {
                  delete settingsArr[idx];
                }
              });
            }
          }
        }
        else {
          if (pSetting.paramProperty === 'csr$') {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && pSetting.paramProperty === 'csr$') {
                delete settingsArr[idx];
              }
            });
          }
        }
      }
      //Impression url
      else {
        if (isChecked) {
          if (pSetting.paramProperty === 's2s') {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && pSetting.paramProperty === 's2s') {
                delete settingsArr[idx];
              }
            });
          }
          
          if (pSetting.paramProperty === 'is_retargeting') {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && pSetting.paramProperty === 'is_retargeting') {
                delete settingsArr[idx];
              }
            });
          }

          if (pSetting.paramProperty === 'af_reengagement_window') {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && pSetting.paramProperty === 'af_reengagement_window') {
                delete settingsArr[idx];
              }
            });
          }

          if (pSetting.paramProperty === 'x_af_reengagement_window') {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && pSetting.paramProperty === 'x_af_reengagement_window') {
                delete settingsArr[idx];
              }
            });
          }

          if (trackingProvider === BRANCH_ID) {
            if (pSetting.paramProperty === '%24s2s' ) {
              settings.forEach((pSetting, idx, settingsArr) => {
                if (pSetting && pSetting.paramProperty === '%24s2s') {
                  delete settingsArr[idx];
                }
              });
            }
          }
        }
        else {
          if (pSetting.paramProperty === 'csrds') {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && pSetting.paramProperty === 'csrds') {
                delete settingsArr[idx];
              }
            });
          }
        }
      }
    });
  }
  
  handleProbabilisticChange(e, settings, isImp){
    var clientsideChecked = this.state.Clientside;
    var isIOS = this.state.response && this.state.response.osPlatform === 1?true:false;
    var trackingProvider = this.state.trackingProvider;

    var has_idfa = false;
    var has_advertising_id = false;
    var has_tg_aaid = false;
    var has_gps_adid = false;
    var has_android_id = false;
    var has_af_ua = false;
    var has_tg_x_af_ip = false;
    var has_tg_user_agent = false;
    var has_af_lang = false;
    var has_tg_x_af_lang = false;
    var has_af_siteid = false;
    var has_tg_site_id = false;
    var has_ip_address = false;
    var has_user_agent = false;

    settings && (settings || []).forEach((pSetting, idx, array) => {
      if (e.currentTarget.checked) {    
        settings.forEach((pSetting,idx, settingsArr)=>{
          if(pSetting){
            if (trackingProvider === ADJUST_ID) {
              if (pSetting.paramProperty === 'idfa' || pSetting.paramProperty === 'gps_adid' || pSetting.paramProperty === 'android_id') {
                settings.forEach((pSetting, idx, settingsArr) => {
                  if (pSetting && (pSetting.paramProperty === 'idfa' || pSetting.paramProperty === 'gps_adid' || pSetting.paramProperty === 'android_id')) {
                    delete settingsArr[idx];
                  }
                });
              }
              if (pSetting.paramProperty === 'ip_address') {
                has_ip_address = true;
                pSetting.paramValue = '{remote_ip}';
              }
              if (pSetting.paramProperty === 'user_agent') {
                has_user_agent = true;
                pSetting.paramValue = '{uagent}';
              }
            }
            else if (trackingProvider === TRAFFICGUARD_ID) {
              if (pSetting.paramProperty === 'ip_address') {
                has_ip_address = true;
                pSetting.paramValue = '{remote_ip}';
              }
              if (pSetting.paramProperty === 'x_af_ip') {
                has_tg_x_af_ip = true;
                pSetting.paramValue = '{remote_ip}';
              }
              if (pSetting.paramProperty === 'user_agent' && !clientsideChecked) {
                has_tg_user_agent = true;
                pSetting.paramValue = '{uagent}';
              }
              if (pSetting.paramProperty === 'x_af_ua' && clientsideChecked) {
                has_tg_user_agent = true;
                pSetting.paramValue = '{uagent}';
              }
              if (pSetting.paramProperty === 'x_af_lang') {
                has_tg_x_af_lang = true;
                pSetting.paramValue = '{acclang}';
              }
              if (pSetting.paramProperty === 'site_id') {
                has_tg_site_id = true;
                if (isIOS) {
                  if (!clientsideChecked) {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                  else {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                }
                else {
                  pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                }

              }
              if (pSetting && (pSetting.paramProperty === 'idfa' || pSetting.paramProperty === 'aaid')) {
                delete settingsArr[idx];
              }
            }
            else {
              if(pSetting.paramProperty==='af_ua'){
                has_af_ua = true;
                pSetting.paramValue = '{uagent}';
              }
              if(pSetting.paramProperty==='af_lang'){
                has_af_lang = true;
                pSetting.paramValue = '{acclang}';
              }
              if(pSetting.paramProperty==='af_siteid'){
                has_af_siteid = true;
                if(isIOS){
                  if (!clientsideChecked) {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                  else {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                }
                else{
                  pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                }
                
              }
              if(pSetting && (pSetting.paramProperty==='idfa' || pSetting.paramProperty==='advertising_id')){
                delete settingsArr[idx];
              }
            }
          }
        });
      }
      //Unchecked
      else
      {
        //var prefix = this.getAppsflyerPrefix();
        has_af_siteid = false;
        has_tg_site_id = false;
        
        settings.forEach((pSetting,idx, settingsArr)=>{
          if(pSetting){
            if (trackingProvider !== ADJUST_ID)
            {
              if (trackingProvider !== TRAFFICGUARD_ID) {
                if (pSetting.paramProperty === 'idfa') {
                  has_idfa = true;
                  pSetting.paramValue = '{com_idfa}'; //default idfa value
                }
                if (pSetting.paramProperty === 'advertising_id') {
                  has_advertising_id = true;
                  pSetting.paramValue = '{com_gaid}'; //default advertising_id value
                }
                if (pSetting.paramProperty === 'af_siteid') {
                  has_af_siteid = true;
                  if (isIOS) {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                  else {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                }
                if (pSetting && (pSetting.paramProperty === 'af_ua' || pSetting.paramProperty === 'af_lang')) {
                  delete settingsArr[idx];
                }
              }
              else {
                if (pSetting.paramProperty === 'idfa') {
                  has_idfa = true;
                  pSetting.paramValue = '{com_idfa}'; //default idfa value
                }
                if (pSetting.paramProperty === 'aaid') {
                  has_tg_aaid = true;
                  pSetting.paramValue = '{com_gaid}'; //default advertising_id value
                }
                if (pSetting.paramProperty === 'site_id') {
                  has_tg_site_id = true;
                  if (isIOS) {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                  else {
                    pSetting.paramValue = '{af_siteid_macro_0d3g100125}';
                  }
                }
                if (pSetting && (pSetting.paramProperty === 'user_agent' || pSetting.paramProperty === 'x_af_lang')) {
                  delete settingsArr[idx];
                }
              }

            }            
            else
            {
              
              if(pSetting && (pSetting.paramProperty==='ip_address' || pSetting.paramProperty==='user_agent')){
                delete settingsArr[idx];
              }

              if(pSetting.paramProperty==='idfa'){
                has_idfa = true;
                pSetting.paramValue = '{com_idfa}'; //default idfa value
              }
              if(pSetting.paramProperty==='gps_adid'){
                has_gps_adid = true;
                pSetting.paramValue = '{com_gaid_lc}'; //default gps_adid value
              }
              if(pSetting.paramProperty==='android_id'){
                has_android_id = true;
                pSetting.paramValue = '{com_gaid_lc}'; //default android_id value
              }
            }
          } 
          
        });
      }
    });

    if (!isImp) {
      if (e.currentTarget.checked) {
        if (trackingProvider === ADJUST_ID) {
          if (!has_ip_address) {
            settings.push({ paramProperty: 'ip_address', paramValue: '{remote_ip}' });
          }
          if (!has_user_agent) {
            settings.push({ paramProperty: 'user_agent', paramValue: '{uagent}' });
          }
        }
        else if (trackingProvider === TRAFFICGUARD_ID) {
          if (!has_ip_address && !clientsideChecked) {
            settings.push({ paramProperty: 'ip_address', paramValue: '{remote_ip}' });
          }
          if (!has_tg_x_af_ip && clientsideChecked) {
            settings.push({ paramProperty: 'x_af_ip', paramValue: '{remote_ip}' });
          }
          if (!has_user_agent && !clientsideChecked) {
            settings.push({ paramProperty: 'user_agent', paramValue: '{uagent}' });
          }
          if (!has_tg_user_agent && clientsideChecked) {
            settings.push({ paramProperty: 'x_af_ua', paramValue: '{uagent}' });
          }
          if (!has_tg_x_af_lang) {
            settings.push({ paramProperty: 'x_af_lang', paramValue: '{acclang}' });
          }
        }
        else {
          if (!has_af_ua) {
            settings.push({ paramProperty: 'af_ua', paramValue: '{uagent}' });
          }
          if (!has_af_lang) {
            settings.push({ paramProperty: 'af_lang', paramValue: '{acclang}' });
          }
          if (!has_af_siteid && isIOS) {
            settings.push({ paramProperty: 'af_siteid', paramValue: '{af_siteid_macro_0d3g100125}' });
          }
        }
      }
      else {
        if (trackingProvider === ADJUST_ID) {
          if (!has_idfa) {
            settings.push({ paramProperty: 'idfa', paramValue: '{com_idfa}' });
          }
          if (!has_gps_adid) {
            settings.push({ paramProperty: 'gps_adid', paramValue: '{com_gaid_lc}' });
          }
          if (!has_android_id) {
            settings.push({ paramProperty: 'android_id', paramValue: '{com_gaid_lc}' });
          }
        }
        else if (trackingProvider === TRAFFICGUARD_ID) {
          if (!has_idfa) {
            settings.push({ paramProperty: 'idfa', paramValue: '{com_idfa}' });
          }
          if (!has_tg_aaid) {
            settings.push({ paramProperty: 'aaid', paramValue: '{com_gaid}' });
          }
          if (!has_tg_site_id) {
            settings.push({ paramProperty: 'site_id', paramValue: isIOS ? '{af_siteid_macro_0d3g100125}' : '{af_siteid_macro_0d3g100125}' });
          }
        }
        else {
          if (!has_idfa) {
            settings.push({ paramProperty: 'idfa', paramValue: '{com_idfa}' });
          }
          if (!has_advertising_id) {
            settings.push({ paramProperty: 'advertising_id', paramValue: '{com_gaid}' });
          }
          if (!has_af_siteid) {
            settings.push({ paramProperty: 'af_siteid', paramValue: isIOS ? '{af_siteid_macro_0d3g100125}' : '{af_siteid_macro_0d3g100125}' });
          }
        }
      }
    }

    if(isImp){
      if (trackingProvider !== ADJUST_ID){
        if (e.currentTarget.checked) {
          if (trackingProvider !== TRAFFICGUARD_ID) {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && (pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'af_reengagement_window')) {
                delete settingsArr[idx];
              }
            });
            if (!has_af_lang) {
              settings.push({ paramProperty: 'af_ua', paramValue: '{uagent}' });
            }
            if (!has_af_lang) {
              settings.push({ paramProperty: 'af_lang', paramValue: '{acclang}' });
            }
          }
          else {
            settings.forEach((pSetting, idx, settingsArr) => {
              if (pSetting && (pSetting.paramProperty === 'is_retargeting' || pSetting.paramProperty === 'x_af_reengagement_window')) {
                delete settingsArr[idx];
              }
              if (pSetting && (pSetting.paramProperty === 'user_agent')) {
                has_user_agent = true;
              }
              if (pSetting && (pSetting.paramProperty === 'x_af_ua')) {
                has_tg_user_agent = true;
              }
            });

            if (!has_user_agent && !clientsideChecked) {
              settings.push({ paramProperty: 'user_agent', paramValue: '{uagent}' });
            }
            if(!has_tg_user_agent && clientsideChecked) {
              settings.push({ paramProperty: 'x_af_ua', paramValue: '{uagent}' });
            }
            if (!has_tg_x_af_lang) {
              settings.push({ paramProperty: 'x_af_lang', paramValue: '{acclang}' });
            }
          }

        }
        else {
          var has_is_retargeting = false;
          var has_af_reengagement_window = false;
          var has_tg_x_af_reengagement_window = false;
          settings.forEach((pSetting, idx, settingsArr) => {
            if (pSetting) {
              if (pSetting.paramProperty === 'is_retargeting') {
                has_is_retargeting = true;
                if (clientsideChecked) {
                  pSetting.paramValue = 'true';
                } else {
                  delete settingsArr[idx];
                }
              }
              if (trackingProvider === APPSFLYER_ID) {
                if (pSetting.paramProperty === 'af_reengagement_window') {
                  has_af_reengagement_window = true;
                  if (clientsideChecked) {
                    pSetting.paramValue = '60d';
                  } else {
                    delete settingsArr[idx];
                  }
                }
              }
              if (trackingProvider === TRAFFICGUARD_ID) {
                if (pSetting.paramProperty === 'x_af_reengagement_window') {
                  has_tg_x_af_reengagement_window = true;
                  if (clientsideChecked) {
                    pSetting.paramValue = '60d';
                  } else {
                    delete settingsArr[idx];
                  }
                }
              }

              if (pSetting && (pSetting.paramProperty === 'af_ua' || pSetting.paramProperty === 'af_lang')) {
                delete settingsArr[idx];
              }
              if (pSetting && (pSetting.paramProperty === 'x_af_ua' || pSetting.paramProperty === 'user_agent')) {
                delete settingsArr[idx];
              }
            }
          });

          if (!has_is_retargeting && !clientsideChecked) {
            //settings.push({paramProperty: 'is_retargeting', paramValue: 'true'});
          }
          if (trackingProvider === APPSFLYER_ID) {
            if (!has_af_reengagement_window && !clientsideChecked) {
             //settings.push({ paramProperty: 'af_reengagement_window', paramValue: '60d' });
            }
            if (!has_idfa) {
              settings.push({ paramProperty: 'idfa', paramValue: '{com_idfa}' });
            }
            if (!has_advertising_id) {
              settings.push({ paramProperty: 'advertising_id', paramValue: '{com_gaid}' });
            }
          }
          if (trackingProvider === TRAFFICGUARD_ID) {
            if (!has_tg_x_af_reengagement_window && !clientsideChecked) {
              //settings.push({ paramProperty: 'x_af_reengagement_window', paramValue: '60d' });
            }
            if (!has_idfa) {
              settings.push({ paramProperty: 'idfa', paramValue: '{com_idfa}' });
            }
            if (!has_tg_aaid) {
              settings.push({ paramProperty: 'aaid', paramValue: '{com_gaid}' });
            }
          }
        }
      }
    }
  }
  
  allowSave(){
    if(this.state.formIsValid===false){
      return true;
    }
    var allowSave = true;
    var parameterSettingsValid = true;
    var eventMappingsValid = true;
    
    if(this.state.parameterSettings){
      this.state.parameterSettings.forEach((pSetting,idx)=>{
        if(pSetting&&pSetting.paramValue&&pSetting.paramValue!==''){
          if(!pSetting.paramProperty||pSetting.paramProperty===''){
            parameterSettingsValid = false;
          }
        }
      });
    }
    
    if(this.state.showEventMapping && (!this.state.campaignBiddingModels || (this.state.campaignBiddingModels && this.state.campaignBiddingModels.length<1))){
      eventMappingsValid = false;
    }
    
    if(this.state.showEventMapping && this.state.campaignBiddingModels && this.state.campaignBiddingModels.length>0 ) 
    {
      var eventMappings = this.state.eventMappings;
      if (!eventMappings || !eventMappings.length) {
        eventMappingsValid = false;
      } else {
        eventMappingsValid = true;
        eventMappings.forEach((mapping, idx) => {
          var modelId = mapping.modelId;
          var providerToken = mapping.providerToken;
          if (modelId === null || modelId === "no-selection" || modelId.length === 0 || providerToken.length === 0) {
            eventMappingsValid = false;
          }
        });
      }
    }
      
    if(
      !this.state.campaignId ||
      !parameterSettingsValid ||
      !eventMappingsValid
    ){
      allowSave = false;
    }
    return !allowSave;
  }
  
  resetStateData(){
    this.setState({
      campaignId: null,
      trackingProvider: initTrackingProvidersList()[0].id,
      Passthrough: false,
      Clientside: false,
      Probabilistic: false,
      checkboxClicked: false,
      afViewthroughLookbackName: 'af_viewthrough_lookback',
      afViewthroughLookback: '',
      afViewthroughLookbackRemoved: false,
      clickUrl: null,
      impressionUrl: null,
      hasClickUrl: false,
      hasImpressionUrl: false,
      showParameterSettings: false,
      showEventMapping: false,
      isPIE: false,
      parameterSettings:[{
        paramProperty: '',
        paramValue: ''
      }],
      impUrlParameterSettings:[{
        paramProperty: '',
        paramValue: ''
      }],
      eventMappings: null,
      campaignBiddingModels: null,
      providerToken: null,
      selectedEventType: '',
      response: null,
      eventToken: null,
      unlockParametersPassword: null,
      campaignName: null
    });
  }

  handleAddEventMapping(){
    var state = this.state;
    state.eventMappings.push({modelId: '', eventType:'', eventName:'', providerToken:''});
    this.setState({ eventMappings: state.eventMappings });
  }

  handleRemoveEventMapping(index){
    var state = this.state;
    var removeEventMapping = state.eventMappings.splice(index, 1);
    var removeParam = removeEventMapping&&removeEventMapping.length>0?[{paramProperty:"event_callback_"+removeEventMapping[0].providerToken,paramValue:''}]:[{paramProperty:'',paramValue:''}];
    this.removeParameterFromImpressionUrl(removeParam,true);
    this.setState({ eventMappings: state.eventMappings });
  }
  
  handleAddParameterSetting(){
    var state = this.state;
    state.parameterSettings.push({paramProperty:'',paramValue:''});
    this.setState({ parameterSettings: state.parameterSettings });
  }

  handleRemoveParameterSetting(index){
    var state = this.state;
    var pSetting = state.parameterSettings.splice(index, 1);
    var implnkUrl = this.removeParameterFromImpressionUrl(pSetting);
    state.parameterSettings.forEach((setting, idx)=>{
      if (setting && setting.paramProperty === "implnk$"){
        setting.paramValue = implnkUrl;
        return;
      }
    });
    this.setState({ parameterSettings: state.parameterSettings });
    this.validateParamValueFields();
  }

  handleAddImpUrlParameterSetting(){
    var state = this.state;
    state.impUrlParameterSettings.push({paramProperty:'',paramValue:''});
    this.setState({ impUrlParameterSettings: state.impUrlParameterSettings });
  }

  handleRemoveImpUrlParameterSetting(index){
    var state = this.state;
    var pSetting = state.impUrlParameterSettings.splice(index, 1);
    var implnkUrl = this.removeParameterFromImpressionUrl(pSetting);
    state.parameterSettings.forEach((setting, idx)=>{
      if (setting && setting.paramProperty === "implnk$"){
        setting.paramValue = implnkUrl;
        return;
      }
    });
    this.setState({ impUrlParameterSettings: state.impUrlParameterSettings, parameterSettings: state.parameterSettings });
    this.validateParamValueFields();
  }
  
  removeParameterFromImpressionUrl(pSetting,save){
    var url = "";
    var parameterSettings = this.state.parameterSettings;
    parameterSettings.forEach((setting, idx)=>{
      if (setting && setting.paramProperty === "implnk$"){
        url = setting.paramValue;
        return;
      }
    });
    
    if(url && url.length>0) {
      url = decodeURIComponent(url);
    
      var removeParam = pSetting && pSetting.length ? pSetting[0].paramProperty : '';
      var urlParsed = new URL(url);
      const searchParams = new URLSearchParams(urlParsed.search);
      for (const name of searchParams.keys()) {
        if (name === removeParam) {
          searchParams.delete(name);
        }
      }
      urlParsed.search = '?' + searchParams.toString();
  
      if (save) {
        parameterSettings.forEach((setting, idx) => {
          if (setting.paramProperty === "implnk$") {
            setting.paramValue = encodeURIComponent(urlParsed.href);
          }
        });
        this.setState({parameterSettings: parameterSettings});
        this.validateParamValueFields();
      } else {
        return encodeURIComponent(urlParsed.href);
      }
    }else{
      return '';
    }
  }

  editImpressionUrlParameter(pSetting){
    var url = "";
    var parameterSettings = this.state.parameterSettings;
    parameterSettings.forEach((setting, idx)=>{
      if (setting && setting.paramProperty === "implnk$"){
        url = setting.paramValue;
        return;
      }
    });
    if(url){
      url = decodeURIComponent(url);
    }
    var editParam = pSetting&&pSetting.length?pSetting[0].paramProperty:'';
    var editValue = pSetting&&pSetting.length?pSetting[0].paramValue:'';
    var urlParsed = new URL(url);
    const searchParams = new URLSearchParams(urlParsed.search);
    for (const name of searchParams.keys()) {
      if (name===editParam) {
        searchParams.set(name, editValue);
      }
    }
    urlParsed.search = '?'+searchParams.toString()

    return encodeURIComponent(urlParsed.href);
  }

  editImpressionUrlParameterName(pSetting, newParamName){
    if(newParamName){
      var url = "";
      var parameterSettings = this.state.parameterSettings;
      parameterSettings.forEach((setting, idx)=>{
        if (setting && setting.paramProperty === "implnk$"){
          url = setting.paramValue;
          return;
        }
      });
      if(url){
        url = decodeURIComponent(url);
      }
      var editParam = pSetting&&pSetting.length?pSetting[0].paramProperty:'';
      var editValue = pSetting&&pSetting.length?pSetting[0].paramValue:'';
      var urlParsed = new URL(url);
      const searchParams = new URLSearchParams(urlParsed.search);
      for (const name of searchParams.keys()) {
        if (name===editParam) {
          searchParams.delete(name);

          searchParams.set(newParamName, editValue);
        }
      }
      urlParsed.search = '?'+searchParams.toString()

      return encodeURIComponent(urlParsed.href);
    }
    
  }
  
  disallowRemoveParameter(paramProperty,stateResponse){ //TODO: check tracking provider
    var response = stateResponse||this.state.response;
    var isIOS = response && response.osPlatform === 1 ? true : false;
      if ((
        paramProperty === "clickid" || paramProperty === "click_id" || paramProperty === "redirect" || paramProperty === "onehrts" || paramProperty === "af_siteid" || paramProperty === "ip_address" ||
        paramProperty === "csr$" || paramProperty === "csrds" ||
        paramProperty === "af_ip" || paramProperty === "x_af_ip" ||
        paramProperty === "s2s" || paramProperty === "install_callback" ||
        paramProperty.startsWith('event_callback_') ||
        paramProperty === "implnk$" || paramProperty === "advertising_id" || paramProperty === "aaid" || paramProperty === "idfa" ||
        paramProperty === "gps_adid" || paramProperty === "android_id" || paramProperty === "devidreq$" || (paramProperty === "af_sub_siteid" && isIOS) || (paramProperty === "x_af_sub_siteid" && isIOS) || paramProperty === "af_viewthrough_lookback" ||
        paramProperty === "x_af_viewthrough_lookback" ||
        paramProperty === "is_retargeting" || paramProperty === "af_reengagement_window" || paramProperty === "x_af_reengagement_window" ||
        paramProperty === "%24s2s" || paramProperty === "device_ip" || paramProperty === "user_agent" || paramProperty === "%24idfa" || paramProperty === "%24aaid" || paramProperty === "~secondary_publisher" || paramProperty === "~click_id" ||
        paramProperty === "af_ua" || paramProperty === "x_af_ua" || paramProperty === "af_lang" || paramProperty === "x_af_lang" || paramProperty === "af_sub4" || paramProperty === "sub_param_4" || paramProperty === "af_c_id" || paramProperty === "x_af_c_id"
      ) && this.state.unlockParametersPassword !== "4567") {
        return true;
      }
      return false;
  }
  
  getPostbackUrl(eventType){
    var trackingProvider = this.state.trackingProvider;
    var trackingProvidersList = this.state.trackingProvidersList;
    var postbackUrl = ""
    if(trackingProvider!==null&&eventType!==null&&trackingProvidersList){
      var trackings = this.state.trackingProvidersList[eventType];
      trackings.forEach(tracking=>{
        if(tracking.id===trackingProvider){
          postbackUrl = tracking.postback;
        }
      });
    }
    return postbackUrl;
  }
  
  processImpressionUrl(url){
    //Remove event_callback_#providerToken# parameters from implnk$ value for editing
    var urlParsed = new URL(url)
    const searchParams = new URLSearchParams(urlParsed.search);
    for (const name of searchParams.keys()) {
      var reg = /^event_callback_/g;
      if (name&&reg.test(name)) {
        searchParams.delete(name);
      }
    }
    urlParsed.search = '?'+searchParams.toString()
    return urlParsed.href;
  }

  countEventCallbackUrlParams(urlParsed){
    const searchParams = new URLSearchParams(urlParsed.search);
    var count=0;
    for (const name of searchParams.keys()) {
      var reg = /^event_callback_/g;
      if (name&&reg.test(name)) {
        count++;
      }
    }
    return count;
  }

  checkUrlForInvalidParamValues(urlParsed){
    //var Url = url.replaceAll('???', '').replaceAll('\\n', '');
    var urlParams = urlParsed.search||'';
    var urlParamsList = urlParams.length>0?urlParams.split('&'):[];
    var isValid = true;
    
    urlParamsList.forEach((param, index)=>{
      if(!isValid){
        return;
      }
      var paramArray = param && param.length>0 ? param.split(/=(.*)/s) : [];
      var paramValue = paramArray.length>1 ? paramArray[1] : '';

      if (paramValue && !this.isParamValueValid(paramValue)) {
        isValid = false;
        return;
      }
    });
    return isValid;
  }
  
  getCampaignInfoCallback(res, campaignId){
    
    var resData = res.data;
    var campaignFound = resData&&resData._id?true:false;

    if (!campaignFound) {
      this.resetStateData();
      this.setState({
        error: "Campaign with id: '" + campaignId + "' was not found.",
        busy: false
      });
      return;
    }
    else
    {
      var trackingProvider = this.state.trackingProvider || '';
      var objectiveUrl = this.state.clickUrl ? this.state.clickUrl : '';
      var objectiveUrlParsed = objectiveUrl.length > 0 ? new URL(objectiveUrl) : null;
      var impressionUrl = this.state.impressionUrl ? this.state.impressionUrl : '';
      var impressionUrlPased = impressionUrl.length > 0 ? new URL(impressionUrl) : null;
      var hasClickUrl = objectiveUrl.length>0 ? true : false;
      var hasImpressionUrl = impressionUrl.length>0 ? true : false;
      
      this.setState({ campaignName: resData.name, hasClickUrl: hasClickUrl, hasImpressionUrl: hasImpressionUrl });

      if (hasClickUrl){
        if(!this.checkUrlForInvalidParamValues(objectiveUrlParsed)){
          this.setState({
            error: "Campaign Click URL is invalid. Parameter values are not allowed to contain these values '://', '?' or '='",
            showParameterSettings: false,
            busy: false
          });
          return;
        }       
      }

      if (hasImpressionUrl){
        if(!this.checkUrlForInvalidParamValues(impressionUrlPased)){
          this.setState({
            error: "Campaign Impression URL is invalid. Parameter values are not allowed to contain these values '://', '?' or '='",
            showParameterSettings: false,
            busy: false
          });
          return;
        }
      }
      
      if (trackingProvider === APPSFLYER_ID && (
        (
          (((objectiveUrlParsed.hostname).search('appsflyer.com') < 0) && ((objectiveUrlParsed.hostname).search('onelink.me') < 0))
          || ((impressionUrlPased &&
            ((impressionUrlPased.hostname).search('appsflyer.com') < 0 && (impressionUrlPased.hostname).search('onelink.me') < 0)
          ) && impressionUrl !== '')) &&
        (
          (((objectiveUrlParsed.hostname).search('onelink.me') < 0) && ((objectiveUrlParsed.hostname).search('appsflyer.com') < 0))
          || ((impressionUrlPased &&
            ((impressionUrlPased.hostname).search('onelink.me') < 0 && (impressionUrlPased.hostname).search('appsflyer.com') < 0)
          ) && impressionUrl !== ''))
      )){
        this.setState({
          error: "Campaign URLs are not consistent with selected tracking provider.",
          showParameterSettings: false,
          busy: false
        });
        return;
      }

      if (trackingProvider === TRAFFICGUARD_ID && (
        (((objectiveUrlParsed.hostname).search('trafficguard.ai') < 0) || ((impressionUrlPased && (impressionUrlPased.hostname).search('trafficguard.ai') < 0) && impressionUrl !== '')) 
      )) {
        this.setState({
          error: "Campaign URLs are not consistent with selected tracking provider.",
          showParameterSettings: false,
          busy: false
        });
        return;
      }

      if (trackingProvider === ADJUST_ID && (
        ((objectiveUrlParsed.hostname).search('adjust.com') < 0) || ((impressionUrlPased && (impressionUrlPased.hostname).search('adjust.com') < 0) && impressionUrl !== '')
      )) {
        this.setState({
          error: "Campaign URLs are not consistent with selected tracking provider.",
          showParameterSettings: false,
          busy: false
        });
        return;
      }

      if (trackingProvider === BRANCH_ID && (
        ((objectiveUrlParsed.hostname).search('app.link') < 0) || ((impressionUrlPased && (impressionUrlPased.hostname).search('impression.link') < 0) && impressionUrl !== '')
      )){
        this.setState({
          error: "Campaign URLs are not consistent with selected tracking provider.",
          showParameterSettings: false,
          busy: false
        });
        return;
      }

    }
      
    var apikey = "5a87c952-8ab4-11e4-a60b-a0d68487f907"; // document.getElementById("userApikey").value; 
    var ownerId = resData&&resData.ownerId?resData.ownerId:'';
      
    axios({
      url: "https://dashboard.minimob.com/dashboardapi/advertiserui/api/campaign/GetTrackingProvidersV2?apikey="+apikey+"&ownerId="+ownerId,
      method: "get",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
    })
        .then(res => this.getTrackingProvidersCallback(resData, res.data))
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;
    
          this.setState({
            error,
            busy: false
          });
  
      });
      
  }

  handleGetCampaignUrls(doProcess) {
    this.hideUrlFields(false);
    this.setState({ 
      busy: true, 
      error: null, 
      msg: null, 
      showParameterSettings: false, 
      campaignName: null,
      highlightedRowClickUrl: null,
      highlightedRowImpUrl: null,
      highlightedParamValueImpUrl: false,
    });

    var campaignId = this.state.campaignId!==null&&(this.state.campaignId).length>0?this.state.campaignId.replaceAll(" ",""):"";

    axios({
      url: "/api/v1/tools/getcampaigninfo?id="+campaignId,
      method: "get",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
    })
      .then(res => this.getCampaignUrlsCallback(res, campaignId, doProcess))
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        this.setState({
          error,
          busy: false
        });

      });
  }

  getCampaignUrlsCallback(res, campaignId, doProcess){

    var resData = res.data;
    var campaignFound = resData&&resData._id?true:false;

    if(!campaignFound){
      this.resetStateData();
      this.setState({
        error: "Campaign with id: '"+campaignId+"' was not found.",
        busy: false
      });
      return;
    }
    
    var objectiveUrl = resData&&resData.objectiveUrl?resData.objectiveUrl:'';
    var description = resData&&resData.description?resData.description:'';
    var objectiveUrlParsed = objectiveUrl.length>0?new URL(objectiveUrl):null;
    
    var clickUrl = '';
    var impressionUrl = '';
    var reg = /^__IMPRESSION__=/g;
    var impressionUrlInDesc = reg.test(description);
    var trackingProviderID = null;
    
    //Check if campaign urls are Appsflyer
    /*if((objectiveUrlParsed.hostname).search('appsflyer.com')<0 || 
        (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('appsflyer.com')<0) || 
        (impressionUrlInDesc && description.search('appsflyer.com')<0))
    {
      this.setState({
        error: "Campaign does not contain Appsflyer urls.",
        busy: false
      });
      return;
    }*/

    if (
      (
        (objectiveUrlParsed.hostname).search('appsflyer.com') !== -1 &&
        (
          (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('appsflyer.com') !== -1) ||
          (impressionUrlInDesc && description.search('appsflyer.com') !== -1) ||
          !objectiveUrlParsed.searchParams.get('implnk$')
        )
      )
      ||
      (
        (objectiveUrlParsed.hostname).search('onelink.me') !== -1 &&
        (
          (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('onelink.me') !== -1) ||
          (impressionUrlInDesc && description.search('onelink.me') !== -1) ||
          !objectiveUrlParsed.searchParams.get('implnk$')
        )
      )
      
    )
    {
      trackingProviderID = APPSFLYER_ID;
    }

    if (
      (
        (objectiveUrlParsed.hostname).search('trafficguard.ai') !== -1 &&
        (
          (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('trafficguard.ai') !== -1) ||
          (impressionUrlInDesc && description.search('trafficguard.ai') !== -1) ||
          !objectiveUrlParsed.searchParams.get('implnk$')
        )
      )
    ) {
      trackingProviderID = TRAFFICGUARD_ID;
    }

    if (
      (objectiveUrlParsed.hostname).search('adjust.com') !== -1 &&
      (
        (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('adjust.com') !== -1) ||
        (impressionUrlInDesc && description.search('adjust.com') !== -1) ||
        !objectiveUrlParsed.searchParams.get('implnk$')
      )
    )
    {
      trackingProviderID = ADJUST_ID;
    }

    if (
      (objectiveUrlParsed.hostname).search('app.link') !== -1 &&
      (
        (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('impression.link') !== -1) ||
        (impressionUrlInDesc && description.search('impression.link') !== -1) ||
        !objectiveUrlParsed.searchParams.get('implnk$')
      )
    )
    {
      trackingProviderID = BRANCH_ID;
    }
    
    if(trackingProviderID === null){
      this.setState({
        error: "Campaign does not contain Appsflyer, Adjust or Branch urls.",
        busy: false
      });
      return;
    }
    
    if(impressionUrlInDesc){
      var urlParts = description.split("__IMPRESSION__=");
      impressionUrl = decodeURIComponent(urlParts[1]);
    }
    else{
      if(objectiveUrlParsed.searchParams.get('implnk$')){
        impressionUrl = decodeURIComponent(objectiveUrlParsed.searchParams.get('implnk$'));
        objectiveUrlParsed.searchParams.delete('implnk$');
      }
    }
    clickUrl = objectiveUrlParsed.href;
    
    this.setState({
      error: null,
      busy: false,
      clickUrl: clickUrl,
      impressionUrl: impressionUrl,
      trackingProvider: trackingProviderID, 
    }, ()=>{ if(doProcess){this.handleGetCampaignInfo();} });
  }
  
  handleGetCampaignInfo() {
    this.hideUrlFields(true);
    this.setState({ busy: true, error: null, msg: null });
    
    var campaignId = this.state.campaignId!==null&&(this.state.campaignId).length>0?this.state.campaignId.replaceAll(" ",""):"";
    
    axios({
      url: "/api/v1/tools/getcampaigninfo?id="+campaignId,
      method: "get",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
    })
      .then(res => this.getCampaignInfoCallback(res, campaignId))
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;
        
          this.setState({
            error,
            busy: false
          });
        
      });
  }

  validateUrl(url){
    var reg = /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;
    var encoded = encodeURI(url);
    if(reg.test(encoded)){
      return {isValid:true};
    }

    return{
      isValid:false,
      message:"Invalid Click URL"
    }
  }
  
  specialChars_url(url){
    var reg = /[<>"']/g;
    if(!reg.test(url)){
      return {isValid:true};
    }

    return{
      isValid:false,
      message:"Invalid Click URL: the following special characters are not allowed < > \" '"
    }
  }

  replaceSpecChars(str){
    if(str){
      return str.replaceAll("%7B","{").replaceAll("%7D","}").replaceAll("%5B", "[").replaceAll("%5D", "]");
    }
    return str;
  }

  getDefaultEventMappings(){
    return [{modelId:'', eventType:'', eventName:'', providerToken:''}];
  }

  hideUrlFields(value){
    this.setState({urlFieldsHidden:value});
  }

  resetFormErrorsValidation(){
    this.setState({formErrors:{}, formErrorsImp:{}, formIsValid:null});
  }

  validateParamValueFields(){
    var parameterSettings = this.state.parameterSettings;
    var impUrlParameterSettings = this.state.impUrlParameterSettings;
    var afViewthroughLookback = this.state.afViewthroughLookback;
    var formErrors = this.state.formErrors;
    var formErrorsImp = this.state.formErrorsImp;
    var formIsValid = this.state.formIsValid;
    formErrors = {};
    formIsValid = true;

    parameterSettings.forEach((param,idx)=>{
      if(param && param.paramValue){
        formErrors[idx]=false;
        
        if(!this.isParamValueValid(param.paramValue)){
          formErrors[idx]=PARAM_VALUE_VALIDATION_MSG;
          formIsValid = false;
        }
      }
    });

    impUrlParameterSettings.forEach((param,idx)=>{
      if(param && param.paramValue){
        formErrorsImp[idx]=false;
        if(param.paramProperty!=='install_callback'){
          if(!this.isParamValueValid(param.paramValue)){
            formErrorsImp[idx]=PARAM_VALUE_VALIDATION_MSG;
            formIsValid = false;
          }
        }
        
      }
    });

    if(!this.isParamValueValid(afViewthroughLookback)){
      formErrorsImp.afViewthroughLookback=PARAM_VALUE_VALIDATION_MSG;
      formIsValid = false;
    }
    
    this.setState({formErrors:formErrors, formErrorsImp:formErrorsImp, formIsValid:formIsValid});
    return formIsValid;
  }
  
  isParamValueValid(paramValue){
    var reg1 = /:\/\//g;
    var reg2 = /\?/g;
    var reg3 = /=/g;

    if (paramValue && (reg1.test(paramValue) || reg2.test(paramValue) || reg3.test(paramValue))) {
      return false;
    }
    return true;
  }

  handleAddClick(){
    this.resetStateData();
    this.setState({showAdd:true, showEditDataFor:null, urlFieldsHidden:false, showTrackingLinkForm:true, showTable:false, msg:null, error:null}, 
      ()=>this.executeScroll(this.formRef) );
  }
  
  handleEditClick(commandData){
    this.resetStateData();
    this.setState({ showAdd:false, showEditDataFor: commandData, urlFieldsHidden:false, showTrackingLinkForm:true, showTable:false, campaignId:commandData.campaignId||'', msg:null, error:null }, 
      ()=>{
        this.handleGetCampaignUrls(true);
        this.executeScroll(this.formRef);
      });
  }
  
  handleCancelClick(){
    this.resetStateData();
    this.setState({showAdd:false, showEditDataFor:null, urlFieldsHidden:false, showTrackingLinkForm:false, showTable:true, msg:null, error:null }, ()=>this.executeScroll(this.topRef));
  }

  handleViewMoreClick(commandData){
    var row = commandData.row;
    var isClick = commandData.isClick;
    var className = isClick?'expanded-click-cell':'expanded-impression-cell';
    
    if(row!==null){
      var api = this.tableRef?.current?.api;
      if (api) {
        if(api.rows([row])
          .nodes()
          .to$().hasClass(className))
        {
          api.rows([row])
            .nodes()
            .to$()
            .removeClass(className);
        } else{
          api.rows([row])
            .nodes()
            .to$()
            .addClass(className);
        }
        
        api.draw(false);
      }
    }
  }
  
  /*Auto pause offers -> Generate TrackingLinkComposer mongo docs*/
  getAutoPauseOffers(){

    this.setState({
      msg: '',
      error: null,
      busy: true
    });

    axios({
      url: "/api/v1/reports/autopause?CampaignState=Active&ModerationStatus=Approved,PendingModeration",
      method: "get",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
    })
      .then(res => {
        
        this.setState({
          msg: '',
          error: null,
          busy: false
        });
        var offersList = res && res.data && res.data.length > 0 ? res.data : [];
        this.autoPauseOffersCallback(offersList);
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        this.setState({
          error,
          busy: false
        });

      });
  }
  
  autoPauseOffersCallback(offersList){
    var offerIds = offersList.map(offer => offer.offerId);
    
    this.setState({
      msg: '',
      error: null,
      busy: true
    });

    axios({
      url: "/api/v1/tools/GetTrackingLinkComposerHistoryByOfferIds",
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      responseType: "json",
      data:{OfferIds:offerIds}
    })
      .then(res => {
        this.setState({
          msg: '',
          error: null,
          busy: false
        });
        
        var moderationData = res&&res.data?res.data:null;
        this.historyCallback(offersList,moderationData);
        
      })
      .catch(err => {
        const error = err.response
          ? err.response.data
          : err.request
            ? err.request
            : err.message;

        this.setState({
          error,
          busy: false
        });

      });
  }

  historyCallback(offersList,moderationData) {
    //var offersList = res && res.data && res.data.length > 0 ? res.data : [];
    //var offerIds = offersList.map(offer => offer.offerId);
    var mongoDocs = '';
    
    offersList.forEach(offer => {
      var campaignId = offer.offerId;

      var objectiveUrl = offer.campaignObjectiveUrl ? offer.campaignObjectiveUrl : '';
      var description = offer.campaignDescription ? offer.campaignDescription : '';
      var objectiveUrlParsed = objectiveUrl.length > 0 ? new URL(objectiveUrl) : null;

      var clickUrl = '';
      var impressionUrl = '';
      var reg = /^__IMPRESSION__=/g;
      var impressionUrlInDesc = reg.test(description);
      var trackingProviderID = null;

      //Determine provider for campaign urls
      if (
        (
          (objectiveUrlParsed.hostname).search('appsflyer.com') !== -1 &&
          (
            (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('appsflyer.com') !== -1) ||
            (impressionUrlInDesc && description.search('appsflyer.com') !== -1) ||
            !objectiveUrlParsed.searchParams.get('implnk$')
          )
        )
        ||
        (
          (objectiveUrlParsed.hostname).search('onelink.me') !== -1 &&
          (
            (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('onelink.me') !== -1) ||
            (impressionUrlInDesc && description.search('onelink.me') !== -1) ||
            !objectiveUrlParsed.searchParams.get('implnk$')
          )
        )

      ) {
        trackingProviderID = APPSFLYER_ID;
      }

      if (
        (
          (objectiveUrlParsed.hostname).search('trafficguard.ai') !== -1 &&
          (
            (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('trafficguard.ai') !== -1) ||
            (impressionUrlInDesc && description.search('trafficguard.ai') !== -1) ||
            !objectiveUrlParsed.searchParams.get('implnk$')
          )
        )
      ) {
        trackingProviderID = TRAFFICGUARD_ID;
      }

      if (
        (objectiveUrlParsed.hostname).search('adjust.com') !== -1 &&
        (
          (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('adjust.com') !== -1) ||
          (impressionUrlInDesc && description.search('adjust.com') !== -1) ||
          !objectiveUrlParsed.searchParams.get('implnk$')
        )
      ) {
        trackingProviderID = ADJUST_ID;
      }

      if (
        (objectiveUrlParsed.hostname).search('app.link') !== -1 &&
        (
          (objectiveUrlParsed.searchParams.get('implnk$') && (objectiveUrlParsed.searchParams.get('implnk$')).search('impression.link') !== -1) ||
          (impressionUrlInDesc && description.search('impression.link') !== -1) ||
          !objectiveUrlParsed.searchParams.get('implnk$')
        )
      ) {
        trackingProviderID = BRANCH_ID;
      }

      /*if(trackingProviderID === null){
        this.setState({
          error: "Campaign does not contain Appsflyer, Adjust or Branch urls.",
          busy: false
        });
        return;
      }*/

      if (impressionUrlInDesc) {
        var urlParts = description.split("__IMPRESSION__=");
        impressionUrl = decodeURIComponent(urlParts[1]);
      } else {
        if (objectiveUrlParsed.searchParams.get('implnk$')) {
          impressionUrl = decodeURIComponent(objectiveUrlParsed.searchParams.get('implnk$'));
          objectiveUrlParsed.searchParams.delete('implnk$');
        }
      }
      clickUrl = objectiveUrlParsed.href;

      /*this.setState({
        error: null,
        busy: false,
        clickUrl: clickUrl,
        impressionUrl: impressionUrl,
        trackingProvider: trackingProviderID,
      });*/

      /////////////////
      /* Determine linkType: Passthrough, Client side, Reengagement, Probabilistic */

      //var isPIE = offer.isCPIOnly!==true?true:false;
      //var isIOS = offer.osPlatform===1?true:false;
      //var resCampaignBiddingModels = resData&&resData.biddingModels?resData.biddingModels:[];
      //var campaignBiddingModels = [];
      //var eventMappings = [];

      //Omit the install event (type="0")
      /*resCampaignBiddingModels.forEach((model,idx)=>{
        if(model.hasOwnProperty("type")&&model.type!==null&&(model.type).toString()!=="0"&&model.deleted!==true&&model.postbackProviderId===ADJUST_ID){
          campaignBiddingModels.push(model);
        }
      });*/

      //Set default selected event type
      //var selectedEventType = campaignBiddingModels.length>0&&campaignBiddingModels[0].hasOwnProperty("type")&&campaignBiddingModels[0].type!==null?(campaignBiddingModels[0].type).toString():'';

      var adjustSelected = trackingProviderID === ADJUST_ID;
      var appsflyerSelected = trackingProviderID === APPSFLYER_ID;
      var trafficguardSelected = trackingProviderID === TRAFFICGUARD_ID;
      var branchSelected = trackingProviderID === BRANCH_ID;

      //var clickUrl = this.state.clickUrl||'';
      //var impressionUrl = this.state.impressionUrl || '';
      clickUrl = clickUrl.replaceAll('???', '');
      clickUrl = clickUrl.replaceAll('\\n', '');
      impressionUrl = impressionUrl.replaceAll('???', '');
      impressionUrl = impressionUrl.replaceAll('\\n', '');

      var clickUrlParsed = clickUrl.length > 0 ? new URL(clickUrl) : null;
      var impressionUrlParsed = impressionUrl.length > 0 ? new URL(impressionUrl) : null;
      //var clickUrlParams = clickUrl.split('?').length>1?clickUrl.split('?')[1]:'';
      //var clickUrlParamsList = clickUrlParams.length>0?clickUrlParams.split('&'):[];
      var passthroughClick = false;
      var passthroughImp = false;
      var clientsideClick = false;
      var clientsideImp = false;
      var probabilisticClick = false;
      var probabilisticImp = false;
      var reengagementClick = false;
      var reengagementImp = false;

      if (adjustSelected) {
        if (clickUrlParsed && clickUrlParsed.host && impressionUrlParsed) {
          clickUrlParsed.host = "s2s.adjust.com";
          clickUrl = clickUrlParsed.href;
        }
        if (impressionUrlParsed && impressionUrlParsed.host) {
          impressionUrlParsed.host = "s2s.adjust.com";
          impressionUrl = impressionUrlParsed.href;
        }
      }

      //Check if passthrough, clientside and probabilistic checkboxes should be checked
      if (clickUrlParsed) {
        if (
          (clickUrlParsed.searchParams.get('advertising_id') === null || clickUrlParsed.searchParams.get('advertising_id') === '{com_gaid}') &&
          (clickUrlParsed.searchParams.get('gps_adid') === null || clickUrlParsed.searchParams.get('gps_adid') === '{com_gaid}') &&
          (clickUrlParsed.searchParams.get('android_id') === null || clickUrlParsed.searchParams.get('android_id') === '{com_gaid}') &&
          (clickUrlParsed.searchParams.get('idfa') === null || clickUrlParsed.searchParams.get('idfa') === '{com_idfa}') &&
          clickUrlParsed.searchParams.get('devidreq$')
        ) {
          passthroughClick = true;
        }

        if (
          clickUrlParsed.searchParams.get('csr$') === 'true' &&
          clickUrlParsed.searchParams.get('onehrts') === null &&
          clickUrlParsed.searchParams.get('redirect') === null &&
          clickUrlParsed.searchParams.get(decodeURIComponent('%24s2s')) === null
        ) {
          clientsideClick = true;
        }

        if (
          clickUrlParsed.searchParams.get('af_ua') === '{uagent}' &&
          clickUrlParsed.searchParams.get('af_lang') === '{acclang}' &&
          clickUrlParsed.searchParams.get('idfa') === null &&
          clickUrlParsed.searchParams.get('advertising_id') === null
        ) {
          probabilisticClick = true;
        }

        if (
          clickUrlParsed.searchParams.get('user_agent') === '{uagent}' &&
          clickUrlParsed.searchParams.get('x_af_lang') === '{acclang}' &&
          clickUrlParsed.searchParams.get('idfa') === null &&
          clickUrlParsed.searchParams.get('aaid') === null
        ) {
          probabilisticClick = true;
        }

        if (
          clickUrlParsed.searchParams.get('is_retargeting') === 'true' &&
          clickUrlParsed.searchParams.get('af_reengagement_window') !== null &&
          clickUrlParsed.searchParams.get('af_siteid') !== null
        ) {
          reengagementClick = true;
        }

        if (
          clickUrlParsed.searchParams.get('is_retargeting') === 'true' &&
          clickUrlParsed.searchParams.get('x_af_reengagement_window') !== null &&
          clickUrlParsed.searchParams.get('site_id') !== null
        ) {
          reengagementClick = true;
        }

      }

      if (impressionUrlParsed) {
        if (
          (impressionUrlParsed.searchParams.get('advertising_id') === null || impressionUrlParsed.searchParams.get('advertising_id') === '{com_gaid}') &&
          (impressionUrlParsed.searchParams.get('gps_adid') === null || impressionUrlParsed.searchParams.get('gps_adid') === '{com_gaid}') &&
          (impressionUrlParsed.searchParams.get('android_id') === null || impressionUrlParsed.searchParams.get('android_id') === '{com_gaid}') &&
          (impressionUrlParsed.searchParams.get('idfa') === null || impressionUrlParsed.searchParams.get('idfa') === '{com_idfa}')
          // && impressionUrlParsed.searchParams.get('devidreq$')
        ) {
          passthroughImp = true;
        }

        if (
          (impressionUrlParsed.searchParams.get('csrds') === 'true') && impressionUrlParsed.searchParams.get(decodeURIComponent('%24s2s')) === null
        ) {
          clientsideImp = true;
        }

        if (
          impressionUrlParsed.searchParams.get('af_ua') === '{uagent}' &&
          impressionUrlParsed.searchParams.get('af_lang') === '{acclang}' &&
          impressionUrlParsed.searchParams.get('idfa') === null &&
          impressionUrlParsed.searchParams.get('advertising_id') === null &&
          impressionUrlParsed.searchParams.get('is_retargeting') === null &&
          impressionUrlParsed.searchParams.get('af_reengagement_window') === null
        ) {
          probabilisticImp = true;
        }

        if (
          impressionUrlParsed.searchParams.get('user_agent') === '{uagent}' &&
          impressionUrlParsed.searchParams.get('x_af_lang') === '{acclang}' &&
          impressionUrlParsed.searchParams.get('idfa') === null &&
          impressionUrlParsed.searchParams.get('aaid') === null &&
          impressionUrlParsed.searchParams.get('is_retargeting') === null &&
          impressionUrlParsed.searchParams.get('x_af_reengagement_window') === null
        ) {
          probabilisticImp = true;
        }

        if (
          impressionUrlParsed.searchParams.get('af_click_lookback') !== null &&
          impressionUrlParsed.searchParams.get('redirect') === 'false' &&
          impressionUrlParsed.searchParams.get('af_siteid') !== null
        ) {
          reengagementImp = true;
        }
      }

      var passthroughChecked = passthroughClick && passthroughImp ? true : false;
      var clientsideChecked = clientsideClick && clientsideImp ? true : false;
      var probabilisticChecked = probabilisticClick && probabilisticImp ? true : false;

      var isTheSame = true;
      const clickSearchParams = new URLSearchParams(clickUrlParsed.search);
      const impSearchParams = new URLSearchParams(impressionUrlParsed ? impressionUrlParsed.search : null);
      for (const name of clickSearchParams.keys()) {
        if (name !== 'onehrts' && name !== 'redirect') {
          if (impressionUrlParsed && !impressionUrlParsed.searchParams.get(name)) {
            isTheSame = false;
          } else {
            if (impressionUrlParsed && (clickUrlParsed.searchParams.get(name) !== impressionUrlParsed.searchParams.get(name).replaceAll('+', ' '))) {
              isTheSame = false;
            }
          }
        }

      }
      for (const name of impSearchParams.keys()) {
        if (name !== 'onehrts' && name !== 'redirect') {
          if (!clickUrlParsed.searchParams.get(name)) {
            isTheSame = false;
          } else {
            if ((clickUrlParsed.searchParams.get(name) !== impressionUrlParsed.searchParams.get(name).replaceAll('+', ' '))) {
              isTheSame = false;
            }
          }
        }
      }

      var reengagementChecked = (clickUrlParsed && impressionUrlParsed && isTheSame && reengagementClick && reengagementImp) ? true : false;

      var linkType = {
        passthrough: passthroughChecked,
        clientside: clientsideChecked,
        probabilistic: probabilisticChecked,
        reengagement: reengagementChecked,
      };
      
      var clickurlObj = null;
      var impressionurlObj = null;

      if(clickUrlParsed && clickSearchParams){
        clickurlObj = {};
        for (const name of clickSearchParams.keys()) {
          if(name!==null) {
            var clickParamValue = clickSearchParams.get(name);
            clickurlObj[name] = clickParamValue !== null && clickParamValue.length > 0 ? clickParamValue : '';
          }
        }
      }
      
      if(impressionUrlParsed && impSearchParams){
        impressionurlObj = {};
        for (const name of impSearchParams.keys()) {
          if(name!==null){
            var impParamValue = impSearchParams.get(name);
            impressionurlObj[name] = impParamValue!==null&&impParamValue.length>0?impParamValue:'';
          }          
        }
      }      
      
      /*var clickurlObj = {
        af_siteid: clickUrlParsed ? clickUrlParsed.searchParams.get('af_siteid') : '',
        af_click_lookback: clickUrlParsed ? clickUrlParsed.searchParams.get('af_click_lookback') : '',
        af_viewthrough_lookback: clickUrlParsed ? clickUrlParsed.searchParams.get('af_viewthrough_lookback') : '',
        af_reengagement_window: clickUrlParsed ? clickUrlParsed.searchParams.get('af_reengagement_window') : ''
      };
      var impressionurlObj = {
        af_siteid: impressionUrlParsed ? impressionUrlParsed.searchParams.get('af_siteid') : '',
        af_click_lookback: impressionUrlParsed ? impressionUrlParsed.searchParams.get('af_click_lookback') : '',
        af_viewthrough_lookback: impressionUrlParsed ? impressionUrlParsed.searchParams.get('af_viewthrough_lookback') : '',
        af_reengagement_window: impressionUrlParsed ? impressionUrlParsed.searchParams.get('af_reengagement_window') : ''
      };*/
      
      var trackingProvider = null;
      if (appsflyerSelected) {
        trackingProvider = "Appsflyer";
      } else if (trafficguardSelected) {
        trackingProvider = "Trafficguard";
      } else if (adjustSelected) {
        trackingProvider = "Adjust";
      } else if (branchSelected) {
        trackingProvider = "Branch";
      }
      
      var moderationDateUTC = null;
      var moderatorId = null;
      if(moderationData && moderationData.length>0){
        var modData = _.find(moderationData, function(d) { return d.offerId === campaignId; });
        moderationDateUTC = modData.moderationDate?modData.moderationDate:null;
        moderatorId = modData.moderatorId?modData.moderatorId:null;
      }
      ////////////////

      trackingProvider = trackingProvider!==null?'"'+trackingProvider+'"':null;
      trackingProviderID = trackingProviderID!==null?'"'+trackingProviderID+'"':null;
      mongoDocs = mongoDocs +
        '{ ' +
        'campaignId: "' + campaignId + '", ' +
        'linkType: ' + JSON.stringify(linkType) + ', ' +
        'trackingProviderId: '+ trackingProviderID + ', ' +
        'trackingProvider: ' + trackingProvider + ', ' +
        'clickurl: ' + JSON.stringify(clickurlObj) + ', ' +
        'impressionurl: ' + JSON.stringify(impressionurlObj) + ', ' +
        'moderationDate: '+ (moderationDateUTC!==null ? 'ISODate("' + moderationDateUTC + '")' : null) + ', ' +
        'moderatorId: ' + (moderatorId!==null ? '"' + moderatorId + '"' : null) + 
        '} ';
    });
  }



  getTrackingProvidersCallback(resData, trackingProvidersList) {

    this.setState({
      trackingProvidersList: trackingProvidersList
    });

    var isPIE = resData && resData.isCPIOnly !== true ? true : false;
    var isIOS = resData && resData.osPlatform === 1 ? true : false;
    var resCampaignBiddingModels = resData && resData.biddingModels ? resData.biddingModels : [];
    var campaignBiddingModels = [];
    var eventMappings = [];

    //Omit the install event (type="0")
    resCampaignBiddingModels.forEach((model, idx) => {
      if (model.hasOwnProperty("type") && model.type !== null && (model.type).toString() !== "0" && model.deleted !== true && model.postbackProviderId === ADJUST_ID) {
        campaignBiddingModels.push(model);
      }
    });

    //Set default selected event type
    var selectedEventType = campaignBiddingModels.length > 0 && campaignBiddingModels[0].hasOwnProperty("type") && campaignBiddingModels[0].type !== null ? (campaignBiddingModels[0].type).toString() : '';

    var adjustSelected = this.state.trackingProvider === ADJUST_ID;
    var appsflyerSelected = this.state.trackingProvider === APPSFLYER_ID;
    var trafficguardSelected = this.state.trackingProvider === TRAFFICGUARD_ID;
    var branchSelected = this.state.trackingProvider === BRANCH_ID;
    var showEventMapping = isPIE && adjustSelected;

    var clickUrl = this.state.clickUrl || '';
    var impressionUrl = this.state.impressionUrl || '';
    clickUrl = clickUrl.replaceAll('???', '');
    clickUrl = clickUrl.replaceAll('\\n', '');
    var regex = /(?:\r|\n|\r\n)/g;
    clickUrl = clickUrl.replace(regex, '');
    impressionUrl = impressionUrl.replaceAll('???', '');
    impressionUrl = impressionUrl.replaceAll('\\n', '');
    impressionUrl = impressionUrl.replace(regex, '');

    var clickUrlParsed = clickUrl.length > 0 ? new URL(clickUrl) : null;
    var impressionUrlParsed = impressionUrl.length > 0 ? new URL(impressionUrl) : null;
    var clickUrlParams = clickUrl.split('?').length > 1 ? clickUrl.split('?')[1] : '';
    var clickUrlParamsList = clickUrlParams.length > 0 ? clickUrlParams.split('&') : [];
    var hasClickid = false;
    var hasAf_ip = false;
    var hasTg_ip_address = false;
    var hasAf_ad = false;
    var hasTg_x_af_ad = false;
    var hasAf_ad_id = false;
    var hasTg_creative_id = false;
    var hasAf_adset = false;
    var hasTg_creative_set_id = false;
    var hasAf_adset_id = false;
    var hasTg_x_af_adset_id = false;
    var hasOnehrts = false;
    var hasRedirect = false;
    var hasAfsiteid = false;
    var hasTgsiteid = false;
    var hasS2s = false;
    var hasInstallCallback = false;
    var has_AppsFlyer_advertising_id = false;
    var has_Trafficguard_aaid = false;
    var has_AppsFlyer_idfa = false;
    var has_Trafficguard_idfa = false;
    var has_Adjust_idfa = false;
    var has_Adjust_gps_adid = false;
    var has_Adjust_android_id = false;
    var has_Adjust_adgroup = false;
    var has_Adjust_creative = false;
    var has_Appsflyer_af_viewthrough_lookback = false;
    var has_Trafficguard_x_af_viewthrough_lookback = false;
    var has_AppsFlyer_af_sub4 = false;
    var has_Trafficguard_sub_param_4 = false;
    var has_Adjust_cpi_event_callback = false;
    var passthroughClick = false;
    var passthroughImp = false;
    var clientsideClick = false;
    var clientsideImp = false;
    var probabilisticClick = false;
    var probabilisticImp = false;
    var reengagementClick = false;
    var reengagementImp = false;
    var has_AppsFlyer_af_sub_siteid = false;
    var has_Trafficguard_x_af_sub_siteid = false;
    var has_csrds = false;
    var has_csr = false;
    var has_is_retargeting = false;
    var has_af_reengagement_window = false;
    var has_tg_x_reengagement_window = false;
    var has_branch_s2s = false;
    var has_device_ip = false;
    var has_user_agent = false;
    var has_tracker_limit = false;
    var has_branch_idfa = false;
    var has_aaid = false;
    var has_secondary_publisher = false;
    var has_impression_install_window_days = false;
    var has_impression_session_start_window_days = false;
    var has_impression_conversion_window_days = false;
    var has_click_install_window_days = false;
    var has_click_session_start_window_days = false;
    var has_click_conversion_window_days = false;

    var has_af_c_id = false;
    var has_c = false;
    var has_af_channel = false;
    var has_af_ad_type = false;


    if (adjustSelected) {
      if (clickUrlParsed && clickUrlParsed.host && impressionUrlParsed) {
        clickUrlParsed.host = "s2s.adjust.com";
        clickUrl = clickUrlParsed.href;
      }
      if (impressionUrlParsed && impressionUrlParsed.host) {
        impressionUrlParsed.host = "s2s.adjust.com";
        impressionUrl = impressionUrlParsed.href;
      }
    }

    //Check if passthrough, clientside and probabilistic checkboxes should be checked
    if (clickUrlParsed) {
      if (
        (clickUrlParsed.searchParams.get('advertising_id') === null || clickUrlParsed.searchParams.get('advertising_id') === '{com_gaid}') &&
        (clickUrlParsed.searchParams.get('gps_adid') === null || clickUrlParsed.searchParams.get('gps_adid') === '{com_gaid}') &&
        (clickUrlParsed.searchParams.get('android_id') === null || clickUrlParsed.searchParams.get('android_id') === '{com_gaid}') &&
        (clickUrlParsed.searchParams.get('idfa') === null || clickUrlParsed.searchParams.get('idfa') === '{com_idfa}') &&
        clickUrlParsed.searchParams.get('devidreq$')
      ) {
        passthroughClick = true;
      }

      if (adjustSelected) {
        if (
          clickUrlParsed.searchParams.get('csr$') === 'true' &&
          clickUrlParsed.searchParams.get('onehrts') === null &&
          clickUrlParsed.searchParams.get('s2s') === null
        ) {
          clientsideClick = true;
        }
      }
      else {
        if (
          clickUrlParsed.searchParams.get('csr$') === 'true' &&
          clickUrlParsed.searchParams.get('onehrts') === null &&
          clickUrlParsed.searchParams.get('redirect') === null &&
          clickUrlParsed.searchParams.get(decodeURIComponent('%24s2s')) === null
        ) {
          clientsideClick = true;
        }
      }

      if (adjustSelected) {
        if (
          clickUrlParsed.searchParams.get('ip_address') === '{remote_ip}' &&
          clickUrlParsed.searchParams.get('user_agent') === '{uagent}' &&
          clickUrlParsed.searchParams.get('idfa') === null &&
          clickUrlParsed.searchParams.get('gps_adid') === null &&
          clickUrlParsed.searchParams.get('android_id') === null
        ) {
          probabilisticClick = true;
        }
      }
      else {
        if (
          clickUrlParsed.searchParams.get('af_ua') === '{uagent}' &&
          clickUrlParsed.searchParams.get('af_lang') === '{acclang}' &&
          clickUrlParsed.searchParams.get('idfa') === null &&
          clickUrlParsed.searchParams.get('advertising_id') === null
        ) {
          probabilisticClick = true;
        }

        if (
          clickUrlParsed.searchParams.get('user_agent') === '{uagent}' &&
          clickUrlParsed.searchParams.get('x_af_lang') === '{acclang}' &&
          clickUrlParsed.searchParams.get('idfa') === null &&
          clickUrlParsed.searchParams.get('aaid') === null
        ) {
          probabilisticClick = true;
        }

        if (
          clickUrlParsed.searchParams.get('x_af_ua') === '{uagent}' &&
          clickUrlParsed.searchParams.get('x_af_lang') === '{acclang}' &&
          clickUrlParsed.searchParams.get('idfa') === null &&
          clickUrlParsed.searchParams.get('aaid') === null
        ) {
          probabilisticClick = true;
        }
      }

      if (
        clickUrlParsed.searchParams.get('is_retargeting') === 'true' &&
        clickUrlParsed.searchParams.get('af_reengagement_window') !== null &&
        clickUrlParsed.searchParams.get('af_siteid') !== null
      ) {
        reengagementClick = true;
      }

      if (
        clickUrlParsed.searchParams.get('is_retargeting') === 'true' &&
        clickUrlParsed.searchParams.get('x_af_reengagement_window') !== null &&
        clickUrlParsed.searchParams.get('site_id') !== null
      ) {
        reengagementClick = true;
      }

    }

    if (impressionUrlParsed) {
      if (
        (impressionUrlParsed.searchParams.get('advertising_id') === null || impressionUrlParsed.searchParams.get('advertising_id') === '{com_gaid}') &&
        (impressionUrlParsed.searchParams.get('gps_adid') === null || impressionUrlParsed.searchParams.get('gps_adid') === '{com_gaid}') &&
        (impressionUrlParsed.searchParams.get('android_id') === null || impressionUrlParsed.searchParams.get('android_id') === '{com_gaid}') &&
        (impressionUrlParsed.searchParams.get('idfa') === null || impressionUrlParsed.searchParams.get('idfa') === '{com_idfa}')
        // && impressionUrlParsed.searchParams.get('devidreq$')
      ) {
        passthroughImp = true;
      }
      if (
        (impressionUrlParsed.searchParams.get('aaid') === null || impressionUrlParsed.searchParams.get('aaid') === '{com_gaid}') &&
        (impressionUrlParsed.searchParams.get('gps_adid') === null || impressionUrlParsed.searchParams.get('gps_adid') === '{com_gaid}') &&
        (impressionUrlParsed.searchParams.get('android_id') === null || impressionUrlParsed.searchParams.get('android_id') === '{com_gaid}') &&
        (impressionUrlParsed.searchParams.get('idfa') === null || impressionUrlParsed.searchParams.get('idfa') === '{com_idfa}')
        // && impressionUrlParsed.searchParams.get('devidreq$')
      ) {
        passthroughImp = true;
      }

      if (adjustSelected) {
        if (
          impressionUrlParsed.searchParams.get('csrds') === 'true' &&
          impressionUrlParsed.searchParams.get('s2s') === null
          /* && impressionUrlParsed.host === 'view.adjust.com'*/
        ) {
          clientsideImp = true;
        }
      }
      else {
        if (
          (impressionUrlParsed.searchParams.get('csrds') === 'true') && impressionUrlParsed.searchParams.get(decodeURIComponent('%24s2s')) === null
        ) {
          clientsideImp = true;
        }
      }

      if (adjustSelected) {
        if (
          impressionUrlParsed.searchParams.get('ip_address') === '{remote_ip}' &&
          impressionUrlParsed.searchParams.get('user_agent') === '{uagent}' &&
          impressionUrlParsed.searchParams.get('idfa') === null &&
          impressionUrlParsed.searchParams.get('gps_adid') === null &&
          impressionUrlParsed.searchParams.get('android_id') === null
        ) {
          probabilisticImp = true;
        }
      }
      else {
        if (
          impressionUrlParsed.searchParams.get('af_ua') === '{uagent}' &&
          impressionUrlParsed.searchParams.get('af_lang') === '{acclang}' &&
          impressionUrlParsed.searchParams.get('idfa') === null &&
          impressionUrlParsed.searchParams.get('advertising_id') === null &&
          impressionUrlParsed.searchParams.get('is_retargeting') === null &&
          impressionUrlParsed.searchParams.get('af_reengagement_window') === null
        ) {
          probabilisticImp = true;
        }
        if (
          impressionUrlParsed.searchParams.get('user_agent') === '{uagent}' &&
          impressionUrlParsed.searchParams.get('x_af_lang') === '{acclang}' &&
          impressionUrlParsed.searchParams.get('idfa') === null &&
          impressionUrlParsed.searchParams.get('aaid') === null &&
          impressionUrlParsed.searchParams.get('is_retargeting') === null &&
          impressionUrlParsed.searchParams.get('x_af_reengagement_window') === null
        ) {
          probabilisticImp = true;
        }
      }

      if (
        impressionUrlParsed.searchParams.get('af_click_lookback') !== null &&
        impressionUrlParsed.searchParams.get('redirect') === 'false' &&
        impressionUrlParsed.searchParams.get('af_siteid') !== null
      ) {
        reengagementImp = true;
      }
      if (
        impressionUrlParsed.searchParams.get('click_lookback') !== null &&
        impressionUrlParsed.searchParams.get('redirect') === 'false' &&
        impressionUrlParsed.searchParams.get('site_id') !== null
      ) {
        reengagementImp = true;
      }
    }

    var passthroughChecked = passthroughClick && passthroughImp ? true : false;
    var clientsideChecked = clientsideClick && clientsideImp ? true : false;
    var probabilisticChecked = probabilisticClick && probabilisticImp ? true : false;

    var isTheSame = true;
    const clickSearchParams = new URLSearchParams(clickUrlParsed.search);
    const impSearchParams = new URLSearchParams(impressionUrlParsed ? impressionUrlParsed.search : null);
    for (const name of clickSearchParams.keys()) {
      if (name !== 'onehrts' && name !== 'redirect') {
        if (impressionUrlParsed && !impressionUrlParsed.searchParams.get(name)) {
          isTheSame = false;
        }
        else {
          if (impressionUrlParsed && (clickUrlParsed.searchParams.get(name) !== impressionUrlParsed.searchParams.get(name).replaceAll('+', ' '))) {
            isTheSame = false;
          }
        }
      }

    }
    for (const name of impSearchParams.keys()) {
      if (name !== 'onehrts' && name !== 'redirect') {
        if (!clickUrlParsed.searchParams.get(name)) {
          isTheSame = false;
        }
        else {
          if ((clickUrlParsed.searchParams.get(name) !== impressionUrlParsed.searchParams.get(name).replaceAll('+', ' '))) {
            isTheSame = false;
          }
        }
      }
    }

    var reengagementChecked = (clickUrlParsed && impressionUrlParsed && isTheSame && reengagementClick && reengagementImp) ? true : false;

    var baseImpressionUrl = impressionUrl.split('?').length > 0 ? impressionUrl.split('?')[0] : '';
    var impressionUrlParams = impressionUrl.split('?').length > 1 ? impressionUrl.split('?')[1] : '';
    var impressionUrlParamsList = impressionUrlParams.length > 0 ? impressionUrlParams.split('&') : [];
    var encodedImpressionUrl = '';
    var afViewthroughLookback = appsflyerSelected ? '24h' : '';
    var tgViewthroughLookback = trafficguardSelected ? '24h' : '';
    var impUrlParameterSettings = [];
    var eventToken = null;
    //var prefix = this.getAppsflyerPrefix();
    var appsflyerSelectedAndHasClickUrl = this.appsflyerSelectedAndHasClickUrl();
    var trafficguardSelectedAndHasClickUrl = this.trafficguardSelectedAndHasClickUrl();

    if (impressionUrl.length > 0) {
      if (impressionUrlParamsList.length > 0) {

        // Populate Adjust CPI Event Token for editing
        if (adjustSelected && !isPIE) {
          var eventCallbackCount = this.countEventCallbackUrlParams(impressionUrlParsed);
          hasInstallCallback = impressionUrlParsed.searchParams.get("install_callback") ? true : false;

          const searchParams = new URLSearchParams(impressionUrlParsed.search);
          for (const name of searchParams.keys()) {
            var reg = /^event_callback_/g;
            if (name && reg.test(name)) {
              eventToken = name.replace("event_callback_", "");
            }
          }

          if (eventCallbackCount === 1 && !hasInstallCallback) {
            has_Adjust_cpi_event_callback = true;
          }
        }

        impressionUrlParamsList.forEach((urlParam, idx) => {
          var paramProperty = urlParam ? urlParam.split('=')[0] : '';
          var paramValue = urlParam ? urlParam.split('=')[1] : '';

          if (paramProperty === "csrds") {
            has_csrds = true;
            impressionUrlParamsList[idx] = "csrds=true";
          }

          if (appsflyerSelected) {
            if (paramProperty === "clickid") {
              hasClickid = true;
              impressionUrlParamsList[idx] = "clickid={clickid}";
            }
            if (paramProperty === 'af_dp' || paramProperty === 'deep_link_value') {
              var paramVal = impressionUrlParamsList[idx].split('=')[1];
              impressionUrlParamsList[idx] = paramProperty + "=" + encodeURIComponent(paramVal);
            }
            if (paramProperty === "af_click_lookback" && !reengagementChecked) {
              impressionUrlParamsList[idx] = "af_viewthrough_lookback=24h";
            }
            /*if (paramProperty === "af_viewthrough_lookback") {
              has_Appsflyer_af_viewthrough_lookback = true;
              impressionUrlParamsList[idx] = "af_viewthrough_lookback=24h";
              afViewthroughLookback = "24h";
            }*/
            if (paramProperty === "af_viewthrough_lookback") {
              has_Appsflyer_af_viewthrough_lookback = true;
              impressionUrlParamsList[idx] = "af_viewthrough_lookback=" + paramValue;
              afViewthroughLookback = paramValue;
            }
            if (paramProperty === "af_ip") {
              hasAf_ip = true;
              impressionUrlParamsList[idx] = "af_ip={remote_ip}";
            }
            if (paramProperty === "af_ad") {
              hasAf_ad = true;
            }
            if (paramProperty === "af_ad_id") {
              hasAf_ad_id = true;
            }
            if (paramProperty === "af_adset") {
              hasAf_adset = true;
            }
            if (paramProperty === "af_adset_id") {
              hasAf_adset_id = true;
            }

            if (paramProperty === "af_c_id") {
              has_af_c_id = true;
            }
            if (paramProperty === "c") {
              has_c = true;
            }
            if (paramProperty === "af_channel") {
              has_af_channel = true;
            }
            if (paramProperty === "af_af_ad_type") {
              has_af_ad_type = true;
            }

            if (paramProperty === "af_sub4") {
              has_AppsFlyer_af_sub4 = true;
            }
            if (paramProperty === "af_siteid") {
              hasAfsiteid = true;
              if (probabilisticChecked && isIOS) {
                impressionUrlParamsList[idx] = "af_siteid={af_siteid_macro_0d3g100125}";
              } else if (reengagementChecked) {
                impressionUrlParamsList[idx] = "af_siteid=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}");
              } else if (isTheSame) {
                impressionUrlParamsList[idx] = "af_siteid=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}");
              }
              else {
                impressionUrlParamsList[idx] = "af_siteid=" + (isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}");
              }
            }
            if (paramProperty === "af_sub_siteid" && isIOS) {
              has_AppsFlyer_af_sub_siteid = true;
              impressionUrlParamsList[idx] = "af_sub_siteid=[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}";
            }
            if (paramProperty === "advertising_id") {
              has_AppsFlyer_advertising_id = true;
              impressionUrlParamsList[idx] = "advertising_id={com_gaid}";
            }
            if (paramProperty === "idfa") {
              has_AppsFlyer_idfa = true;
              impressionUrlParamsList[idx] = "idfa={com_idfa}";
            }
            if (paramProperty === "onehrts") {
              hasOnehrts = true;
              impressionUrlParamsList[idx] = "onehrts=true";
            }

            if (paramProperty === "is_retargeting") {
              if (clientsideChecked) {
                has_is_retargeting = true;
                impressionUrlParamsList[idx] = "is_retargeting=true";
              }
              else {
                delete impressionUrlParamsList[idx];
              }
            }
            if (paramProperty === "af_reengagement_window") {
              if (clientsideChecked) {
                has_af_reengagement_window = true;
                impressionUrlParamsList[idx] = "af_reengagement_window=60d";
              }
              else {
                delete impressionUrlParamsList[idx];
              }
            }

            if ((paramProperty === "af_c_id") && (paramValue === "[[campaign_id]]")) {
              //has_af_c_id = true;
              delete impressionUrlParamsList[idx];
            }

            /*
            if (paramProperty === "redirect") {
              hasRedirect = true;
              impressionUrlParamsList[idx] = "redirect=false";
            }
            */
          }

          if (trafficguardSelected) {
            if (paramProperty === "partner_click_id") {
              hasClickid = true;
              impressionUrlParamsList[idx] = "partner_click_id={clickid}";
            }
            if (paramProperty === 'x_af_dp' || paramProperty === 'deep_link_value') {
              var paramVal = impressionUrlParamsList[idx].split('=')[1];
              impressionUrlParamsList[idx] = paramProperty + "=" + encodeURIComponent(paramVal);
            }
            if (paramProperty === "click_lookback" && !reengagementChecked) {
              impressionUrlParamsList[idx] = "x_af_viewthrough_lookback=24h";
            }
            /*if (paramProperty === "af_viewthrough_lookback") {
              has_Appsflyer_af_viewthrough_lookback = true;
              impressionUrlParamsList[idx] = "af_viewthrough_lookback=24h";
              afViewthroughLookback = "24h";
            }*/
            if (paramProperty === "x_af_viewthrough_lookback") {
              has_Trafficguard_x_af_viewthrough_lookback = true;
              impressionUrlParamsList[idx] = "x_af_viewthrough_lookback=" + paramValue;
              tgViewthroughLookback = paramValue;
            }
            if (paramProperty === "ip_address") {
              hasTg_ip_address = true;
              impressionUrlParamsList[idx] = "ip_address={remote_ip}";
            }
            if (paramProperty === "x_af_ad") {
              hasTg_x_af_ad = true;
            }
            if (paramProperty === "creative_id") {
              hasTg_creative_id = true;
            }
            if (paramProperty === "creative_set_id") {
              hasTg_creative_set_id = true;
            }
            if (paramProperty === "x_af_adset_id") {
              hasTg_x_af_adset_id = true;
            }
            if (paramProperty === "sub_param_4") {
              has_Trafficguard_sub_param_4 = true;
            }
            if (paramProperty === "site_id") {
              hasTgsiteid = true;
              if (probabilisticChecked && isIOS) {
                impressionUrlParamsList[idx] = "site_id={af_siteid_macro_0d3g100125}";
              } else if (reengagementChecked) {
                impressionUrlParamsList[idx] = "site_id=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}");
              } else if (isTheSame) {
                impressionUrlParamsList[idx] = "site_id=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}");
              }
              else {
                impressionUrlParamsList[idx] = "site_id=" + (isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}");
              }
            }
            if (paramProperty === "x_af_sub_siteid" && isIOS) {
              has_Trafficguard_x_af_sub_siteid = true;
              impressionUrlParamsList[idx] = "x_af_sub_siteid=[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}";
            }
            if (paramProperty === "aaid") {
              has_Trafficguard_aaid = true;
              impressionUrlParamsList[idx] = "aaid={com_gaid}";
            }
            if (paramProperty === "idfa") {
              has_Trafficguard_idfa = true;
              impressionUrlParamsList[idx] = "idfa={com_idfa}";
            }
            if (paramProperty === "onehrts") {
              hasOnehrts = true;
              impressionUrlParamsList[idx] = "onehrts=true";
            }

            if (paramProperty === "is_retargeting") {
              if (clientsideChecked) {
                has_is_retargeting = true;
                impressionUrlParamsList[idx] = "is_retargeting=true";
              }
              else {
                delete impressionUrlParamsList[idx];
              }
            }
            if (paramProperty === "x_af_reengagement_window") {
              if (clientsideChecked) {
                has_tg_x_reengagement_window = true;
                impressionUrlParamsList[idx] = "x_af_reengagement_window=60d";
              }
              else {
                delete impressionUrlParamsList[idx];
              }
            }

            if ((paramProperty === "x_af_c_id") && (paramValue === "[[campaign_id]]")) {
              //has_af_c_id = true;
              delete impressionUrlParamsList[idx];
            }

            /*
            if (paramProperty === "redirect") {
              hasRedirect = true;
              impressionUrlParamsList[idx] = "redirect=false";
            }
            */
          }

          if (adjustSelected) {
            if (paramProperty === "click_id") {
              hasClickid = true;
              impressionUrlParamsList[idx] = "click_id={clickid}";
            }
            if (paramProperty === "clickid") {
              delete impressionUrlParamsList[idx];
            }
            if (paramProperty === "s2s") {
              hasS2s = true;
              impressionUrlParamsList[idx] = "s2s=1";
            }
            if (paramProperty === "idfa") {
              has_Adjust_idfa = true;
              impressionUrlParamsList[idx] = "idfa={com_idfa}";
            }
            if (paramProperty === "gps_adid") {
              has_Adjust_gps_adid = true;
              impressionUrlParamsList[idx] = "gps_adid={com_gaid_lc}";
            }
            if (paramProperty === "android_id") {
              has_Adjust_android_id = true;
              impressionUrlParamsList[idx] = "android_id={com_gaid_lc}";
            }
            if (paramProperty === "onehrts") {
              hasOnehrts = true;
              impressionUrlParamsList[idx] = "onehrts=true";
            }
          }

          if (branchSelected) {
            paramProperty = decodeURIComponent(paramProperty);
            //impressionUrlParamsList[idx] = paramProperty+"="+paramValue;
            if (paramProperty === "~click_id") {
              hasClickid = true;
              impressionUrlParamsList[idx] = "~click_id={clickid}";
            }
            if (paramProperty === "%24s2s" || paramProperty === decodeURIComponent("%24s2s")) {
              has_branch_s2s = true;
              impressionUrlParamsList[idx] = "%24s2s=true";
            }
            if (paramProperty === "device_ip") {
              has_device_ip = true;
              impressionUrlParamsList[idx] = "device_ip={remote_ip}";
            }
            if (paramProperty === "user_agent") {
              has_user_agent = true;
              impressionUrlParamsList[idx] = "user_agent={uagent}";
            }
            if (paramProperty === "%24idfa" || paramProperty === decodeURIComponent("%24idfa")) {
              if (isIOS) {
                has_branch_idfa = true;
                //impressionUrlParamsList[idx] = "%24idfa={org_idfa}";
                impressionUrlParamsList[idx] = "%24idfa={com_idfa}";
              }
              else {
                delete impressionUrlParamsList[idx];
              }
            }
            if (paramProperty === "%24aaid" || paramProperty === decodeURIComponent("%24aaid")) {
              if (!isIOS) {
                has_aaid = true;
                // impressionUrlParamsList[idx] = "%24aaid={org_gaid}";
                impressionUrlParamsList[idx] = "%24aaid={com_gaid}";
              }
              else {
                delete impressionUrlParamsList[idx];
              }

            }
            if (paramProperty === "~secondary_publisher") {
              has_secondary_publisher = true;
              impressionUrlParamsList[idx] = "~secondary_publisher={publisherid}_{pub_subid}";
            }

            /*imp only */
            if (paramProperty === "$impression_install_window_days") {
              has_impression_install_window_days = true;
              impressionUrlParamsList[idx] = "$impression_install_window_days=7";
            }
            if (paramProperty === "$impression_session_start_window_days") {
              has_impression_session_start_window_days = true;
              impressionUrlParamsList[idx] = "$impression_session_start_window_days=1";
            }
            if (paramProperty === "$impression_conversion_window_days") {
              has_impression_conversion_window_days = true;
              impressionUrlParamsList[idx] = "$impression_conversion_window_days=7";
            }

            if (paramProperty === "onehrts") {
              hasOnehrts = true;
              impressionUrlParamsList[idx] = "onehrts=true";
            }
          }
        });
      }

      if (!hasClickid && !adjustSelected) {
        if (trafficguardSelected) {
          //impressionUrlParamsList.push("partner_click_id={clickid}");
        }
        else {
          impressionUrlParamsList.push(branchSelected ? "~click_id={clickid}" : "clickid={clickid}");
        }        
      }

      if (appsflyerSelected) {
        if (!hasClickid) {
          impressionUrlParamsList.push("clickid={clickid}");
        }
        if (!hasAfsiteid) {
          if (probabilisticChecked && isIOS) {
            impressionUrlParamsList.push("af_siteid={af_siteid_macro_0d3g100125}");
          } else if (reengagementChecked) {
            impressionUrlParamsList.push("af_siteid=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}"));
          } else if (isTheSame) {
            impressionUrlParamsList.push("af_siteid=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}"));
          }
          else {
            impressionUrlParamsList.push("af_siteid=" + (isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}"));
          }
        }
        if (!has_AppsFlyer_af_sub_siteid && isIOS) {
          impressionUrlParamsList.push("af_sub_siteid=[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}");
        }
        if (!has_AppsFlyer_advertising_id && !probabilisticChecked) {
          impressionUrlParamsList.push("advertising_id={com_gaid}");
        }
        if (!has_AppsFlyer_idfa && !probabilisticChecked) {
          impressionUrlParamsList.push("idfa={com_idfa}");
        }
        if (!hasOnehrts) {
          impressionUrlParamsList.push("onehrts=true");
        }
        /*if(!has_af_c_id){
          impressionUrlParamsList.push("af_c_id=[[campaign_id]]");
        }*/
        /*
         if (!hasRedirect) {
          impressionUrlParamsList.push("redirect=false");
        }
        */
        if (!has_Appsflyer_af_viewthrough_lookback && !reengagementChecked) {
          impressionUrlParamsList.push("af_viewthrough_lookback=24h");
        }
        if (!hasAf_ip) {
          impressionUrlParamsList.push("af_ip={remote_ip}");
        }
        if (!hasAf_ad) {
          impressionUrlParamsList.push("af_ad={af_ad_macro}");
        }
        if (!hasAf_ad_id) {
          impressionUrlParamsList.push("af_ad_id={af_ad_id_macro}");
        }
        if (!hasAf_adset) {
          impressionUrlParamsList.push("af_adset={af_adset_macro}");
        }
        if (!hasAf_adset_id) {
          impressionUrlParamsList.push("af_adset_id={af_adset_id_macro}");
        }

        if (!has_af_c_id) {
          impressionUrlParamsList.push("af_c_id={bundle_id}");
        }
        if (!has_c) {
          impressionUrlParamsList.push("c=inapp_display");
        }
        if (!has_af_channel) {
          impressionUrlParamsList.push("af_channel=inapp_display");
        }
        if (!has_af_ad_type) {
          impressionUrlParamsList.push("af_ad_type=interstitial");
        }

        if (!has_AppsFlyer_af_sub4 && appsflyerSelectedAndHasClickUrl) {
          impressionUrlParamsList.push("af_sub4={source_publisher}_{originsubid}");
        }
        if (!has_is_retargeting && !clientsideChecked && !probabilisticChecked) {
          //impressionUrlParamsList.push("is_retargeting=true");
        }
        if (!has_af_reengagement_window && !clientsideChecked && !probabilisticChecked) {
          //impressionUrlParamsList.push("af_reengagement_window=60d");
        }
      }

      if (trafficguardSelected) {
        if (!hasClickid) {
          impressionUrlParamsList.push("partner_click_id={clickid}");
        }
        if (!hasTgsiteid) {
          if (probabilisticChecked && isIOS) {
            impressionUrlParamsList.push("site_id={af_siteid_macro_0d3g100125}");
          } else if (reengagementChecked) {
            impressionUrlParamsList.push("site_id=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}"));
          } else if (isTheSame) {
            impressionUrlParamsList.push("site_id=" + (isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}"));
          }
          else {
            impressionUrlParamsList.push("site_id=" + (isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}"));
          }
        }
        if (!has_Trafficguard_x_af_sub_siteid && isIOS) {
          impressionUrlParamsList.push("x_af_sub_siteid=[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}");
        }
        if (!has_Trafficguard_aaid && !probabilisticChecked) {
          impressionUrlParamsList.push("aaid={com_gaid}");
        }
        if (!has_Trafficguard_idfa && !probabilisticChecked) {
          impressionUrlParamsList.push("idfa={com_idfa}");
        }
        if (!hasOnehrts) {
          impressionUrlParamsList.push("onehrts=true");
        }
        /*if(!has_af_c_id){
          impressionUrlParamsList.push("af_c_id=[[campaign_id]]");
        }*/
        /*
         if (!hasRedirect) {
          impressionUrlParamsList.push("redirect=false");
        }
        */
        if (!has_Trafficguard_x_af_viewthrough_lookback && !reengagementChecked) {
          impressionUrlParamsList.push("x_af_viewthrough_lookback=24h");
        }
        if (!hasTg_ip_address) {
          impressionUrlParamsList.push("ip_address={remote_ip}");
        }
        if (!hasTg_x_af_ad && !isIOS) {
          impressionUrlParamsList.push("x_af_ad={af_ad_macro}");
        }
        if (!hasTg_creative_id && !isIOS) {
          impressionUrlParamsList.push("creative_id={af_ad_id_macro}");
        }
        if (!hasTg_creative_set_id && !isIOS) {
          impressionUrlParamsList.push("creative_set_id={af_adset_macro}");
        }
        if (!hasTg_x_af_adset_id && !isIOS) {
          impressionUrlParamsList.push("x_af_adset_id={af_adset_id_macro}");
        }
        if (!has_Trafficguard_sub_param_4 && trafficguardSelectedAndHasClickUrl) {
          impressionUrlParamsList.push("sub_param_4={source_publisher}_{originsubid}");
        }
        if (!has_is_retargeting && !clientsideChecked && !probabilisticChecked) {
          //impressionUrlParamsList.push("is_retargeting=true");
        }
        if (!has_tg_x_reengagement_window && !clientsideChecked && !probabilisticChecked) {
          //impressionUrlParamsList.push("x_af_reengagement_window=60d");
        }
      }


      if (adjustSelected) {
        if (!hasClickid) {
          impressionUrlParamsList.push("click_id={clickid}"); //click_id instead of clickid for adjust
        }
        if (!hasS2s && !has_csrds) {
          impressionUrlParamsList.push("s2s=1");
        }
        if (!has_Adjust_idfa && !probabilisticChecked) {
          impressionUrlParamsList.push("idfa={com_idfa}");
        }
        if (!has_Adjust_gps_adid && !probabilisticChecked) {
          impressionUrlParamsList.push("gps_adid={com_gaid_lc}");
        }
        if (!has_Adjust_android_id && !probabilisticChecked) {
          impressionUrlParamsList.push("android_id={com_gaid_lc}");
        }
        if (!hasOnehrts && !clientsideChecked) {
          impressionUrlParamsList.push("onehrts=true");
          hasOnehrts = true;
        }
      }

      if (branchSelected) {
        if (!has_branch_s2s && !clientsideChecked) {
          impressionUrlParamsList.push("%24s2s=true");
        }
        if (!has_device_ip) {
          impressionUrlParamsList.push("device_ip={remote_ip}");
        }
        if (!has_user_agent) {
          impressionUrlParamsList.push("user_agent={uagent}");
        }
        if (!has_branch_idfa && isIOS) {
          //impressionUrlParamsList.push("%24idfa={org_idfa}");
          impressionUrlParamsList.push("%24idfa={com_idfa}");
        }
        if (!has_aaid && !isIOS) {
          //impressionUrlParamsList.push("%24aaid={org_gaid}");
          impressionUrlParamsList.push("%24aaid={com_gaid}");
        }
        if (!has_secondary_publisher) {
          impressionUrlParamsList.push("~secondary_publisher={publisherid}_{pub_subid}");
        }
        if (!has_impression_install_window_days) {
          impressionUrlParamsList.push("$impression_install_window_days=7");
        }
        if (!has_impression_session_start_window_days) {
          impressionUrlParamsList.push("$impression_session_start_window_days=1");
        }
        if (!has_impression_conversion_window_days) {
          impressionUrlParamsList.push("$impression_conversion_window_days=7");
        }
        if (!hasOnehrts) {
          impressionUrlParamsList.push("onehrts=true");
        }
      }

      if (appsflyerSelected) {
        //Encoding exceptions for params af_dp & deep_link_value
        var impressionUrlParamsList2 = impressionUrlParamsList.map((param, index) => {
          var paramProperty = param ? param.split('=')[0] : '';
          var paramValue = param ? param.split('=')[1] : '';
          if (paramProperty === 'af_dp' || paramProperty === 'deep_link_value') {
            return (paramProperty + "=" + decodeURIComponent(paramValue));
          }
          return param;
        });

        impressionUrl = baseImpressionUrl + "?" + impressionUrlParamsList2.join('&');
        encodedImpressionUrl = this.replaceSpecChars(encodeURIComponent(impressionUrl));
      }

      if (trafficguardSelected) {
        //Encoding exceptions for params af_dp & deep_link_value
        var impressionUrlParamsList2 = impressionUrlParamsList.map((param, index) => {
          var paramProperty = param ? param.split('=')[0] : '';
          var paramValue = param ? param.split('=')[1] : '';
          if (paramProperty === 'x_af_dp' || paramProperty === 'deep_link_value') {
            return (paramProperty + "=" + decodeURIComponent(paramValue));
          }
          return param;
        });

        impressionUrl = baseImpressionUrl + "?" + impressionUrlParamsList2.join('&');
        encodedImpressionUrl = this.replaceSpecChars(encodeURIComponent(impressionUrl));
      }
      else {
        impressionUrl = baseImpressionUrl + "?" + impressionUrlParamsList.join('&');
        encodedImpressionUrl = this.replaceSpecChars(encodeURIComponent(impressionUrl));
      }

      // Filter out event mappings from impression url editable parameters 
      impressionUrlParamsList = impressionUrlParamsList.filter(function (impUrlParam) {
        var reg = /^event_callback_/g;
        if (impUrlParam && reg.test(impUrlParam)) {
          return false; // skip
        }
        return true;
      })

      impUrlParameterSettings = impressionUrlParamsList.map((param) => {
        var paramProperty = param ? param.split('=')[0] : '';
        var paramValue = param ? param.split('=')[1] : '';
        var readOnly = this.disallowRemoveParameter(paramProperty, resData);
        return ({
          paramProperty: paramProperty,
          paramValue: this.replaceSpecChars(decodeURIComponent(paramValue)),
          readOnly: readOnly
        });
      });

      // Put Read-only parameters last
      impUrlParameterSettings = _.sortBy(impUrlParameterSettings, [function (setting) { return setting.readOnly; }]);
    }

    //reset vars
    hasClickid = false;
    hasAfsiteid = false;
    hasAf_ip = false;
    hasAf_ad = false;
    hasAf_ad_id = false;
    hasAf_adset = false;
    hasAf_adset_id = false;
    has_AppsFlyer_af_sub4 = false;
    has_AppsFlyer_af_sub_siteid = false;
    has_Trafficguard_x_af_sub_siteid = false;
    hasOnehrts = false;
    hasRedirect = false;
    hasAfsiteid = false;
    hasS2s = false;
    hasInstallCallback = false;
    has_AppsFlyer_advertising_id = false;
    has_AppsFlyer_idfa = false;
    has_Adjust_idfa = false;
    has_Adjust_gps_adid = false;
    has_Adjust_android_id = false;
    has_Adjust_adgroup = false;
    has_Adjust_creative = false;
    has_Adjust_cpi_event_callback = false;
    has_csr = false;
    has_csrds = false;
    has_branch_s2s = false;
    has_device_ip = false;
    has_user_agent = false;
    has_tracker_limit = false;
    has_branch_idfa = false;
    has_aaid = false;
    has_secondary_publisher = false;

    has_af_c_id = false;
    has_c = false;
    has_af_channel = false;
    has_af_ad_type = false;

    // Populate Adjust CPI Event Token for editing
    if (adjustSelected && !isPIE) {
      eventCallbackCount = this.countEventCallbackUrlParams(clickUrlParsed);
      hasInstallCallback = clickUrlParsed.searchParams.get("install_callback") ? true : false;

      const searchParams = new URLSearchParams(clickUrlParsed.search);
      for (const name of searchParams.keys()) {
        reg = /^event_callback_/g;
        if (name && reg.test(name)) {
          eventToken = name.replace("event_callback_", "");
        }
      }

      if (eventCallbackCount === 1 && !hasInstallCallback) {
        has_Adjust_cpi_event_callback = true;
      }
    }

    var parameterSettings = clickUrlParamsList.map((urlParam, idx, paramsArr) => {
      var paramProperty = urlParam ? urlParam.split('=')[0] : '';
      var paramValue = urlParam ? urlParam.split('=')[1] : '';

      if (paramProperty === "csr$") {
        has_csr = true;
        paramValue = "true";
      }

      if (appsflyerSelected) {
        if (paramProperty === "clickid") {
          hasClickid = true;
          paramValue = "{clickid}";
        }
        if (paramProperty === "af_ip") {
          hasAf_ip = true;
          paramValue = "{remote_ip}";
        }
        if (paramProperty === "af_ad") {
          hasAf_ad = true;
        }
        if (paramProperty === "af_ad_id") {
          hasAf_ad_id = true;
        }
        if (paramProperty === "af_adset") {
          hasAf_adset = true;
        }
        if (paramProperty === "af_adset_id") {
          hasAf_adset_id = true;
        }

        if (paramProperty === "af_c_id") {
          has_af_c_id = true;
        }
        if (paramProperty === "c") {
          has_c = true;
        }
        if (paramProperty === "af_channel") {
          has_af_channel = true;
        }
        if (paramProperty === "af_ad_type") {
          has_af_ad_type = true;
        }

        if (paramProperty === "af_sub4") {
          has_AppsFlyer_af_sub4 = true;
        }
        if (paramProperty === "af_siteid") {
          hasAfsiteid = true;
          if (probabilisticChecked && isIOS) {
            paramValue = "{af_siteid_macro_0d3g100125}";
          } else if (reengagementChecked) {
            paramValue = isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}";
          } else if (isTheSame || (impressionUrl.length <= 0)) {
            paramValue = isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}";
          }
          else {
            paramValue = isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}";
          }
        }
        if (paramProperty === "af_sub_siteid" && isIOS) {
          has_AppsFlyer_af_sub_siteid = true;
          paramValue = "[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}";
        }
        if (paramProperty === "onehrts") {
          hasOnehrts = true;
          paramValue = "true";
        }
        if (paramProperty === "redirect") {
          hasRedirect = true;
          paramValue = "false";
        }
        if (paramProperty === "advertising_id") {
          has_AppsFlyer_advertising_id = true;
          paramValue = "{com_gaid}";
        }
        if (paramProperty === "idfa") {
          has_AppsFlyer_idfa = true;
          paramValue = "{com_idfa}";
        }
        if ((paramProperty === "af_c_id") && (paramValue === "[[campaign_id]]")) {
          //has_af_c_id = idx;
          delete clickUrlParamsList[idx];
        }
      }

      if (trafficguardSelected) {
        if (paramProperty === "partner_click_id") {
          hasClickid = true;
          paramValue = "{clickid}";
        }
        if (paramProperty === "ip_address") {
          hasTg_ip_address = true;
          paramValue = "{remote_ip}";
        }
        if (paramProperty === "x_af_ad") {
          hasTg_x_af_ad = true;
        }
        if (paramProperty === "creative_id") {
          hasTg_creative_id = true;
        }
        if (paramProperty === "creative_set_id") {
          hasTg_creative_set_id = true;
        }
        if (paramProperty === "x_af_adset_id") {
          hasTg_x_af_adset_id = true;
        }
        if (paramProperty === "sub_param_4") {
          has_Trafficguard_sub_param_4 = true;
        }
        if (paramProperty === "site_id") {
          hasTgsiteid = true;
          if (probabilisticChecked && isIOS) {
            paramValue = "{af_siteid_macro_0d3g100125}";
          } else if (reengagementChecked) {
            paramValue = isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}";
          } else if (isTheSame || (impressionUrl.length <= 0)) {
            paramValue = isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}";
          }
          else {
            paramValue = isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}";
          }
        }
        if (paramProperty === "x_af_sub_siteid" && isIOS) {
          has_Trafficguard_x_af_sub_siteid = true;
          paramValue = "[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}";
        }
        if (paramProperty === "onehrts") {
          hasOnehrts = true;
          paramValue = "true";
        }
        if (paramProperty === "redirect") {
          hasRedirect = true;
          paramValue = "false";
        }
        if (paramProperty === "aaid") {
          has_Trafficguard_aaid = true;
          paramValue = "{com_gaid}";
        }
        if (paramProperty === "idfa") {
          has_Trafficguard_idfa = true;
          paramValue = "{com_idfa}";
        }
        if ((paramProperty === "x_af_c_id") && (paramValue === "[[campaign_id]]")) {
          //has_af_c_id = idx;
          delete clickUrlParamsList[idx];
        }
      }

      if (adjustSelected) {
        if (paramProperty === "click_id") {
          hasClickid = true;
          paramValue = "{clickid}";
        }
        if (paramProperty === "clickid") {
          delete clickUrlParamsList[idx];
        }
        if (paramProperty === "s2s") {
          hasS2s = true;
          paramValue = "1";
        }
        if (paramProperty === "install_callback") {
          hasInstallCallback = true;
          paramValue = this.getPostbackUrl("0");
        }
        if (paramProperty === "idfa" && impressionUrlParsed) {
          has_Adjust_idfa = true;
          paramValue = "{com_idfa}";
        }
        if (paramProperty === "gps_adid") {
          has_Adjust_gps_adid = true;
          paramValue = "{com_gaid_lc}";
        }
        if (paramProperty === "android_id") {
          has_Adjust_android_id = true;
          paramValue = "{com_gaid_lc}";
        }
        if (!impressionUrlParsed) {
          if (paramProperty === "tracker_limit") {
            has_tracker_limit = true;
            paramValue = "250000";
          }
          if (paramProperty === "ip_address") {
            has_device_ip = true;
            paramValue = "{remote_ip}";
          }
          if (paramProperty === "user_agent") {
            has_user_agent = true;
            paramValue = "{uagent}";
          }
          if (paramProperty === "adgroup") {
            has_Adjust_adgroup = true;
          }
          if (paramProperty === "creative") {
            has_Adjust_creative = true;
          }
        }

        if (paramProperty === "onehrts") {
          hasOnehrts = true;
          paramValue = "true";
        }

        //Event mappings

        //Populate Event mappings for editing
        var reg = /^event_callback_/g;

        if (reg.test(paramProperty) && !has_Adjust_cpi_event_callback) {
          var providerToken = paramProperty.replace("event_callback_", "");
          var paramUrl = paramValue ? new URL(decodeURIComponent(paramValue)) : null;

          if (paramUrl) {
            if (paramUrl.searchParams.get("event_id") && paramUrl.searchParams.get("event_type")) {
              var eventId = paramUrl.searchParams.get("event_id") || '';
              //var eventType = paramUrl.searchParams.get("event_type") || '';
              var eventFound = false;
              /*
               eventMappings.forEach((evMapping,idx, evMappingsArr)=>{
                if(evMapping&&(evMapping.modelId).toString()===eventId){
                  evMapping.providerToken=providerToken;
                  eventFound=true;
                }
              });
              */

              if (campaignBiddingModels.length > 0) {
                campaignBiddingModels.forEach((model, idx, modelsArr) => {

                  if (model && (model.modelId).toString() === eventId) {
                    //var modelId = (model.modelId).toString();
                    var eventType = (model.type).toString();
                    var eventName = '';
                    eventFound = true;

                    eventMappings.push({
                      modelId: eventId,
                      eventType: eventType,
                      eventName: eventName,
                      providerToken: providerToken,
                    });
                  }

                });
              }

              if (!eventFound) {
                eventMappings.push({
                  modelId: '',
                  eventType: '',
                  eventName: '',
                  providerToken: providerToken,
                });
              }

            }
          }
          delete paramsArr[idx];
        }
      }

      if (branchSelected) {
        if (paramProperty === "~click_id") {
          hasClickid = true;
          paramValue = "{clickid}";
        }
        if (paramProperty === "%24s2s") {
          has_branch_s2s = true;
          paramValue = "true";
        }
        if (paramProperty === "device_ip") {
          has_device_ip = true;
          paramValue = "{remote_ip}";
        }
        if (paramProperty === "user_agent") {
          has_user_agent = true;
          paramValue = "{uagent}";
        }
        if (paramProperty === "%24idfa" && isIOS) {
          has_branch_idfa = true;
          //paramValue = "{org_idfa}";
          paramValue = "{com_idfa}";
        }
        if (paramProperty === "%24aaid" && !isIOS) {
          has_aaid = true;
          //paramValue = "{org_gaid}";
          paramValue = "{com_gaid}";
        }
        if (paramProperty === "~secondary_publisher") {
          has_secondary_publisher = true;
          paramValue = "{publisherid}_{pub_subid}";
        }

        /*click only*/
        /*
        $click_install_window_days=7
        $click_session_start_window_days=1
        $click_conversion_window_days=30
        
        */
        if (paramProperty === "$click_install_window_days") {
          has_click_install_window_days = true;
          paramValue = "7";
        }
        if (paramProperty === "$click_session_start_window_days") {
          has_click_session_start_window_days = true;
          paramValue = "1";
        }
        if (paramProperty === "$click_conversion_window_days") {
          has_click_conversion_window_days = true;
          paramValue = "30";
        }
        if (paramProperty === "onehrts") {
          hasOnehrts = true;
          paramValue = "true";
        }
      }

      return { paramProperty: paramProperty, paramValue: paramValue };
    });

    if (eventMappings.length < 1) {
      eventMappings.push({
        modelId: '',
        eventType: '',
        eventName: '',
        providerToken: '',
      });
    }

    //Remove event_callback_ parameters from parameterSettings for editing
    // and remove parameters: af_c_id, clickid (for Adjust only)
    parameterSettings = parameterSettings.filter(function (pSetting) {
      var reg = /^event_callback_/g;
      if ((pSetting && reg.test(pSetting.paramProperty))
        || (adjustSelected && pSetting.paramProperty === "clickid")) {
        return false; // skip
      }

      if (appsflyerSelected && (pSetting.paramProperty === "af_c_id") && (pSetting.paramValue === "[[campaign_id]]")) {
        return false; // skip
      }

      if (trafficguardSelected && (pSetting.paramProperty === "x_af_c_id") && (pSetting.paramValue === "[[campaign_id]]")) {
        return false; // skip
      }

      return true;
    })

    if (!hasClickid && !adjustSelected) {
      if (trafficguardSelected) {
        parameterSettings.push({ paramProperty: "partner_click_id", paramValue: "{clickid}" });
      }
      else {
        parameterSettings.push({ paramProperty: (branchSelected ? "~click_id" : "clickid"), paramValue: "{clickid}" });
      }
      
    }

    if (appsflyerSelected) {
      if (!hasAf_ip) {
        parameterSettings.push({ paramProperty: "af_ip", paramValue: "{remote_ip}" });
      }
      if (!hasAf_ad) {
        parameterSettings.push({ paramProperty: "af_ad", paramValue: "{af_ad_macro}" });
      }
      if (!hasAf_ad_id) {
        parameterSettings.push({ paramProperty: "af_ad_id", paramValue: "{af_ad_id_macro}" });
      }
      if (!hasAf_adset) {
        parameterSettings.push({ paramProperty: "af_adset", paramValue: "{af_adset_macro}" });
      }
      if (!hasAf_adset_id) {
        parameterSettings.push({ paramProperty: "af_adset_id", paramValue: "{af_adset_id_macro}" });
      }

      if (!has_af_c_id) {
        parameterSettings.push({ paramProperty: "af_c_id", paramValue: "{bundle_id}" });
      }
      if (!has_c) {
        parameterSettings.push({ paramProperty: "c", paramValue: "inapp_display" });
      }
      if (!has_af_channel) {
        parameterSettings.push({ paramProperty: "af_channel", paramValue: "inapp_display" });
      }
      if (!has_af_ad_type) {
        parameterSettings.push({ paramProperty: "af_ad_type", paramValue: "interstitial" });
      }

      if (!has_AppsFlyer_af_sub4 && appsflyerSelectedAndHasClickUrl) {
        parameterSettings.push({ paramProperty: "af_sub4", paramValue: "{source_publisher}_{originsubid}" });
      }
      if (!hasAfsiteid) {
        if (probabilisticChecked && isIOS) {
          parameterSettings.push({ paramProperty: "af_siteid", paramValue: "{af_siteid_macro_0d3g100125}" });
        } else if (reengagementChecked) {
          parameterSettings.push({ paramProperty: "af_siteid", paramValue: isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}" });
        } else if (isTheSame || (impressionUrl.length <= 0)) {
          parameterSettings.push({ paramProperty: "af_siteid", paramValue: isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}" });
        } else {
          parameterSettings.push({ paramProperty: "af_siteid", paramValue: isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}" });
        }
      }
      if (!has_AppsFlyer_af_sub_siteid && isIOS) {
        parameterSettings.push({ paramProperty: "af_sub_siteid", paramValue: "[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}" });
      }
      if (!has_AppsFlyer_advertising_id && !probabilisticChecked) {
        parameterSettings.push({ paramProperty: "advertising_id", paramValue: "{com_gaid}" });
      }
      if (!has_AppsFlyer_idfa && !probabilisticChecked) {
        parameterSettings.push({ paramProperty: "idfa", paramValue: "{com_idfa}" });
      }
    }

    if (trafficguardSelected) {
      if (!hasTg_ip_address) {
        parameterSettings.push({ paramProperty: "ip_address", paramValue: "{remote_ip}" });
      }
      if (!hasTg_x_af_ad && !isIOS) {
        parameterSettings.push({ paramProperty: "x_af_ad", paramValue: "{af_ad_macro}" });
      }
      if (!hasTg_creative_id && !isIOS) {
        parameterSettings.push({ paramProperty: "creative_id", paramValue: "{af_ad_id_macro}" });
      }
      if (!hasTg_creative_set_id && !isIOS) {
        parameterSettings.push({ paramProperty: "creative_set_id", paramValue: "{af_adset_macro}" });
      }
      if (!hasTg_x_af_adset_id && !isIOS) {
        parameterSettings.push({ paramProperty: "x_af_adset_id", paramValue: "{af_adset_id_macro}" });
      }
      if (!has_Trafficguard_sub_param_4 && trafficguardSelectedAndHasClickUrl) {
        parameterSettings.push({ paramProperty: "sub_param_4", paramValue: "{source_publisher}_{originsubid}" });
      }
      if (!hasTgsiteid) {
        if (probabilisticChecked && isIOS) {
          parameterSettings.push({ paramProperty: "site_id", paramValue: "{af_siteid_macro_0d3g100125}" });
        } else if (reengagementChecked) {
          parameterSettings.push({ paramProperty: "site_id", paramValue: isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}" });
        } else if (isTheSame || (impressionUrl.length <= 0)) {
          parameterSettings.push({ paramProperty: "site_id", paramValue: isIOS ? "{af_siteid_macro_1d200460}" : "{af_siteid_macro_1d200460}" });
        } else {
          parameterSettings.push({ paramProperty: "site_id", paramValue: isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}" });
        }
      }
      if (!has_Trafficguard_x_af_sub_siteid && isIOS) {
        parameterSettings.push({ paramProperty: "x_af_sub_siteid", paramValue: "[[campaign_id]]%24{pub_subid}%24{originsubid}%24{publisherid}" });
      }
      if (!has_Trafficguard_aaid && !probabilisticChecked) {
        parameterSettings.push({ paramProperty: "aaid", paramValue: "{com_gaid}" });
      }
      if (!has_Trafficguard_idfa && !probabilisticChecked) {
        parameterSettings.push({ paramProperty: "idfa", paramValue: "{com_idfa}" });
      }
    }

    if (adjustSelected) {
      if (!hasClickid) {
        parameterSettings.push({ paramProperty: "click_id", paramValue: "{clickid}" }); //click_id instead of clickid for adjust
      }
      if (!has_Adjust_idfa && !probabilisticChecked && impressionUrlParsed) {
        parameterSettings.push({ paramProperty: "idfa", paramValue: "{com_idfa}" });
      }
      if (!has_Adjust_gps_adid && !probabilisticChecked) {
        parameterSettings.push({ paramProperty: "gps_adid", paramValue: "{com_gaid_lc}" });
      }
      if (!has_Adjust_android_id && !probabilisticChecked) {
        parameterSettings.push({ paramProperty: "android_id", paramValue: "{com_gaid_lc}" });
      }
      if (!impressionUrlParsed) {
        if (!has_tracker_limit) {
          parameterSettings.push({ paramProperty: "tracker_limit", paramValue: "250000" });
        }
        if (!has_device_ip) {
          parameterSettings.push({ paramProperty: "ip_address", paramValue: "{remote_ip}" });
        }
        if (!has_user_agent) {
          parameterSettings.push({ paramProperty: "user_agent", paramValue: "{uagent}" });
        }
        if (!has_Adjust_adgroup) {
          parameterSettings.push({ paramProperty: "adgroup", paramValue: "{programmatic}" });
        }
        if (!has_Adjust_creative) {
          parameterSettings.push({ paramProperty: "creative", paramValue: "300x250" });
        }
      }
      if (!hasS2s && !has_csr && impressionUrlParsed) {
        parameterSettings.push({ paramProperty: "s2s", paramValue: 1 });
      }
      if (!hasOnehrts && !clientsideChecked && impressionUrlParsed) {
        parameterSettings.push({ paramProperty: "onehrts", paramValue: "true" });
        hasOnehrts = true;
      }
    }

    if (branchSelected) {
      if (!has_branch_s2s && !clientsideChecked) {
        parameterSettings.push({ paramProperty: "%24s2s", paramValue: "true" });
      }
      if (!has_device_ip) {
        parameterSettings.push({ paramProperty: "device_ip", paramValue: "{remote_ip}" });
      }
      if (!has_user_agent) {
        parameterSettings.push({ paramProperty: "user_agent", paramValue: "{uagent}" });
      }
      if (!has_branch_idfa && isIOS) {
        //parameterSettings.push({ paramProperty: "%24idfa", paramValue: "{org_idfa}" });
        parameterSettings.push({ paramProperty: "%24idfa", paramValue: "{com_idfa}" });
      }
      if (!has_aaid && !isIOS) {
        //parameterSettings.push({ paramProperty: "%24aaid", paramValue: "{org_gaid}" });
        parameterSettings.push({ paramProperty: "%24aaid", paramValue: "{com_gaid}" });
      }
      if (has_aaid && isIOS) {
        parameterSettings = parameterSettings.filter(function (p) { return (p && p.paramProperty !== "%24aaid") });
      }
      if (has_branch_idfa && !isIOS) {
        parameterSettings = parameterSettings.filter(function (p) { return (p && p.paramProperty !== "%24idfa") });
      }
      if (!has_secondary_publisher) {
        parameterSettings.push({ paramProperty: "~secondary_publisher", paramValue: "{publisherid}_{pub_subid}" });
      }

      /* click only */
      if (!has_click_install_window_days) {
        parameterSettings.push({ paramProperty: "$click_install_window_days", paramValue: "7" });
      }
      if (!has_click_session_start_window_days) {
        parameterSettings.push({ paramProperty: "$click_session_start_window_days", paramValue: "1" });
      }
      if (!has_click_conversion_window_days) {
        parameterSettings.push({ paramProperty: "$click_conversion_window_days", paramValue: "30" });
      }
    }

    if (encodedImpressionUrl.length > 0) {
      if (appsflyerSelected) {

        if (!hasOnehrts && !has_csr) {
          parameterSettings.push({ paramProperty: "onehrts", paramValue: "true" });
        }

        if (!hasRedirect && !has_csr) {
          parameterSettings.push({ paramProperty: "redirect", paramValue: "false" });
        }

        parameterSettings.push({ paramProperty: "implnk$", paramValue: encodedImpressionUrl });
      }

      if (trafficguardSelected) {

        if (!hasOnehrts && !has_csr) {
          parameterSettings.push({ paramProperty: "onehrts", paramValue: "true" });
        }

        if (!hasRedirect && !has_csr) {
          parameterSettings.push({ paramProperty: "redirect", paramValue: "false" });
        }

        parameterSettings.push({ paramProperty: "implnk$", paramValue: encodedImpressionUrl });
      }

      if (adjustSelected) {
        if (!hasOnehrts && !has_csr && !clientsideChecked) {
          parameterSettings.push({ paramProperty: "onehrts", paramValue: "true" });
        }

        if (!hasInstallCallback) {
          parameterSettings.push({ paramProperty: "install_callback", paramValue: this.getPostbackUrl("0") });
        }
        //Add install_callback url parameter to impression url
        impressionUrl = this.processImpressionUrl(impressionUrl);
        if (impressionUrl.indexOf("&install_callback=") === -1) {
          impressionUrl += "&install_callback=" + this.getPostbackUrl("0");
        }

        var has_install_callback = false;
        impUrlParameterSettings.forEach(pSetting => {
          if (pSetting.paramProperty === "install_callback") {
            has_install_callback = true;
          }
        });
        if (!has_install_callback) {
          impUrlParameterSettings.push({ paramProperty: "install_callback", paramValue: this.getPostbackUrl("0") });
        }
        encodedImpressionUrl = this.replaceSpecChars(encodeURIComponent(impressionUrl));
        parameterSettings.push({ paramProperty: "implnk$", paramValue: encodedImpressionUrl });
      }

      if (branchSelected) {
        if (!hasOnehrts && !has_csr) {
          parameterSettings.push({ paramProperty: "onehrts", paramValue: "true" });
        }

        parameterSettings.push({ paramProperty: "implnk$", paramValue: encodedImpressionUrl });
      }
    }

    parameterSettings = parameterSettings.map((setting) => {
      if (setting) {
        var readOnly = this.disallowRemoveParameter(setting.paramProperty, resData);
        setting.readOnly = readOnly;
        return (setting);
      }
      return setting;
    });

    // Put Read-only parameters last
    parameterSettings = _.sortBy(parameterSettings, [function (setting) { return setting.readOnly; }]);

    if (resData) {
      resData.clickUrl = clickUrl;
      resData.impressionUrl = impressionUrl;
    }

    this.resetFormErrorsValidation();

    this.setState({
      campaignBiddingModels: campaignBiddingModels,
      showParameterSettings: true,
      parameterSettings: parameterSettings.length > 0 ? parameterSettings : [{ paramProperty: '', paramValue: '' }],
      impUrlParameterSettings: impUrlParameterSettings.length > 0 ? impUrlParameterSettings : [{ paramProperty: '', paramValue: '' }],
      providerToken: null,
      providerTokens: null,
      selectedEventType: selectedEventType,
      showEventMapping: showEventMapping,
      eventMappings: eventMappings,
      isPIE: isPIE,
      error: null,
      busy: false,
      response: resData || null,
      afViewthroughLookback: afViewthroughLookback,
      Passthrough: passthroughChecked,
      Clientside: clientsideChecked,
      Probabilistic: probabilisticChecked,
      Reengagement: reengagementChecked,
      eventToken: eventToken,
      isTheSame: isTheSame,
    });

    this.setClientSideCheckbox();
  };

  setClientSideCheckbox() {
    //If Appsflyer selected and has only click url - check Client Side checkbox by default
    if (this.appsflyerSelectedAndHasClickUrl(true)) {
      //var isChecked = true;
      //this.handleClientsideChange(isChecked, this.state.parameterSettings, this.state.trackingProvider, true);
      //this.handleClientsideChange(isChecked, this.state.impUrlParameterSettings, this.state.trackingProvider);
      //this.setState({ Clientside: isChecked });
    }
    //If Trafficguard selected and has only click url - check Client Side checkbox by default
    if (this.trafficguardSelectedAndHasClickUrl(true)) {
      var isChecked = true;
      this.handleClientsideChange(isChecked, this.state.parameterSettings, this.state.trackingProvider, true);
      this.handleClientsideChange(isChecked, this.state.impUrlParameterSettings, this.state.trackingProvider);
      this.setState({ Clientside: isChecked });
    }
  }

  handleSave() {
    var formIsValid = this.validateParamValueFields();

    this.setState({
      busy: true,
      msg: null,
      error: null,
      formIsValid: formIsValid
    });

    var campaignId = this.state.response !== null && this.state.response._id ? this.state.response._id : null;
    var trackingProvider = this.state.trackingProvider;
    var clickUrl = this.state.response !== null && this.state.response.clickUrl ? this.state.response.clickUrl : '';
    var parameterSettings = this.state.parameterSettings;
    var impUrlParameterSettings = this.state.impUrlParameterSettings;
    var eventMappings = this.state.eventMappings;
    var selectedEventType = "0";  //Select Install event by default
    var postbackUrl = '';
    var finalClickUrl = '';
    var description = null;
    var baseClickUrl = '';
    var adjustSelected = trackingProvider === ADJUST_ID;
    var appsflyerSelected = trackingProvider === APPSFLYER_ID;
    var trafficguardSelected = trackingProvider === TRAFFICGUARD_ID;
    var branchSelected = trackingProvider === BRANCH_ID;
    //var afViewthroughLookback = this.state.afViewthroughLookback;
    var isIOS = this.state.response && this.state.response.osPlatform === 1 ? true : false;
    var impUrlExists = this.state.impressionUrl;

    postbackUrl = this.getPostbackUrl(selectedEventType);

    if (clickUrl.split('?').length > 0 || clickUrl.split('?').length <= 2) {
      baseClickUrl = clickUrl.split('?')[0];
    }
    else {
      this.setState({ busy: false, error: "Invalid Click URL" });
      return;
    }

    var eventMappingParams = [];

    if (adjustSelected) {
      //event_callback_#event_token# = encoded pie postback url
      eventMappingParams = (eventMappings || []).map(evMapping => {
        //var eventType = evMapping.eventType||"";
        var providerToken = evMapping.providerToken || "";
        var postbackUrl = "";
        var campaignBiddingModels = this.state.campaignBiddingModels || [];
        campaignBiddingModels.forEach((model) => {
          if (model.modelId === evMapping.modelId)
            postbackUrl = model.postbackUrl;
        });
        //var postbackUrl = eventType!==null&&eventType.length>0?this.getPostbackUrl(eventType.toString()):"";
        var postbackUrlEncoded = this.replaceSpecChars(postbackUrl);
        var urlParamProperty = "event_callback_" + providerToken;
        var urlParamValue = postbackUrlEncoded;
        if (providerToken && postbackUrlEncoded) {
          var paramObj = { paramProperty: urlParamProperty, paramValue: urlParamValue };
          parameterSettings.push(paramObj);
          return paramObj;
        }
        return null;
      });
    }

    if (adjustSelected && this.state.eventToken) {
      parameterSettings.forEach(pSetting => {
        if (pSetting.paramProperty === "install_callback") {
          pSetting.paramProperty = "event_callback_" + this.state.eventToken
        }
      });

    }

    if (branchSelected) {
      //Remove parameter = "%24aaid" for iOS and parameter = "%24idfa" for android if they exist
      parameterSettings.forEach((pSetting, idx, settingsArray) => {
        if (pSetting.paramProperty === "%24aaid" && isIOS) {
          delete settingsArray[idx];
        }
        if (pSetting.paramProperty === "%24idfa" && !isIOS) {
          delete settingsArray[idx];
        }
      });
    }

    var af_siteid_changed = false;
    var af_siteid_changed_value;
    var clickUrlParams = parameterSettings.map((pSetting, idx) => {

      if (isIOS && this.state.Probabilistic) {
        if (pSetting.paramProperty === "af_siteid" && pSetting.paramValue !== "{af_siteid_macro_0d3g100125}") {
          af_siteid_changed = true;
          af_siteid_changed_value = pSetting.paramValue;
        }
        if (pSetting.paramProperty === "implnk$" && af_siteid_changed) {
          pSetting.paramValue = pSetting.paramValue.replaceAll("{af_siteid_macro_0d3g100125}", af_siteid_changed_value);
        }
      }
      else {
        var af_site_id_value = isIOS ? "{af_siteid_macro_0d3g100125}" : "{af_siteid_macro_0d3g100125}";
        if (this.state.Reengagement) {
          if (pSetting.paramProperty === "af_siteid" && pSetting.paramValue !== af_site_id_value) {
            af_siteid_changed = true;
            af_siteid_changed_value = pSetting.paramValue;
          }
          if (pSetting.paramProperty === "implnk$" && af_siteid_changed) {
            pSetting.paramValue = pSetting.paramValue.replaceAll(af_site_id_value, af_siteid_changed_value);
          }
        }
        else {
          if (pSetting.paramProperty === "af_siteid" && pSetting.paramValue !== af_site_id_value) {
            af_siteid_changed = true;
            af_siteid_changed_value = pSetting.paramValue;
          }
          if (pSetting.paramProperty === "implnk$" && af_siteid_changed) {
            pSetting.paramValue = pSetting.paramValue.replaceAll(af_site_id_value, af_siteid_changed_value);
          }
        }

      }

      if (adjustSelected && pSetting.paramProperty === "implnk$" && this.state.eventToken) {
        pSetting.paramValue = pSetting.paramValue.replaceAll("install_callback", "event_callback_" + this.state.eventToken)
      }

      if (adjustSelected && pSetting.paramProperty === "implnk$") {
        var impUrlDecoded = pSetting.paramValue ? decodeURIComponent(pSetting.paramValue) : '';
        var impURL = new URL(impUrlDecoded);
        //var pSettings = this.state.parameterSettings;

        if (impURL.searchParams.get("s2s") && this.state.Clientside) {
          impURL.searchParams.delete("s2s");
        }

        if (this.state.Probabilistic) {
          if (impURL.searchParams.get("idfa")) {
            impURL.searchParams.delete("idfa");
          }
          if (impURL.searchParams.get("gps_adid")) {
            impURL.searchParams.delete("gps_adid");
          }
          if (impURL.searchParams.get("android_id")) {
            impURL.searchParams.delete("android_id");
          }
        }
        else {
          if (impURL.searchParams.get("ip_address")) {
            impURL.searchParams.delete("ip_address");
          }
          if (impURL.searchParams.get("user_agent")) {
            impURL.searchParams.delete("user_agent");
          }
        }

        if (impURL.searchParams.get('onehrts')) {
          impURL.searchParams.delete('onehrts')
        }

        //pSettings.forEach(pSetting2 => {
        impUrlParameterSettings.forEach(pSetting2 => {
          if (pSetting2.paramProperty !== "implnk$" && pSetting2.paramProperty !== 'install_callback' && !pSetting2.paramProperty.startsWith('event_callback') && pSetting2.paramProperty !== 'devidreq$') {
            if (impURL.searchParams.get(pSetting2.paramProperty)) {
              impURL.searchParams.set(pSetting2.paramProperty, pSetting2.paramValue);
            }
            else {
              impURL.searchParams.append(pSetting2.paramProperty, pSetting2.paramValue);
            }
          }
        });

        (eventMappingParams || []).forEach(evMapping => {
          if (evMapping && !impURL.searchParams.get(evMapping.paramProperty)) {
            impURL.searchParams.append(evMapping.paramProperty, evMapping.paramValue);
          }
        });
        var impUrlEncoded = impURL.href ? this.replaceSpecChars(encodeURIComponent(this.replaceSpecChars(impURL.href))) : '';
        parameterSettings[idx].paramValue = impUrlEncoded;
        return (pSetting.paramProperty + "=" + impUrlEncoded);
      }

      if (appsflyerSelected && pSetting.paramProperty === "implnk$") {
        impUrlDecoded = pSetting.paramValue ? decodeURIComponent(pSetting.paramValue) : '';
        impURL = new URL(impUrlDecoded);
        //var pSettings = this.state.parameterSettings;

        if (impURL.searchParams.get("is_retargeting") && (this.state.Clientside || this.state.Probabilistic)) {
          impURL.searchParams.delete("is_retargeting");
        }
        if (impURL.searchParams.get("af_reengagement_window") && (this.state.Clientside || this.state.Probabilistic)) {
          impURL.searchParams.delete("af_reengagement_window");
        }

        if (impURL.searchParams.get("idfa") && this.state.Probabilistic) {
          impURL.searchParams.delete("idfa");
        }
        if (impURL.searchParams.get("advertising_id") && this.state.Probabilistic) {
          impURL.searchParams.delete("advertising_id");
        }

        if (impURL.searchParams.get("af_ua") && !this.state.Probabilistic) {
          impURL.searchParams.delete("af_ua");
        }
        if (impURL.searchParams.get("af_lang") && !this.state.Probabilistic) {
          impURL.searchParams.delete("af_lang");
        }


        //pSettings.forEach(pSetting2 => {
        impUrlParameterSettings.forEach(pSetting2 => {
          if (pSetting2.paramProperty !== "implnk$" && pSetting2.paramProperty !== 'install_callback' && !pSetting2.paramProperty.startsWith('event_callback') && pSetting2.paramProperty !== 'devidreq$') {

            if (pSetting2.paramProperty === "af_viewthrough_lookback") {
              var afViewthroughLookback = this.state.afViewthroughLookback;
              if (afViewthroughLookback) {
                var hours = afViewthroughLookback.split('h')[0];
                if (hours.length < 1 || (hours.length > 0 && parseInt(hours) >= 24)) {
                  //impURL.searchParams.set('af_viewthrough_lookback', "24h");
                  pSetting2.paramValue = "24h";
                }
                else {
                  pSetting2.paramValue = afViewthroughLookback;
                }
              }
            }

            if (impURL.searchParams.get(pSetting2.paramProperty)) {
              //Encoding exceptions for params af_dp & deep_link_value
              if (pSetting2.paramProperty === "af_dp" || pSetting2.paramProperty === "deep_link_value") {
                impURL.searchParams.set(pSetting2.paramProperty, decodeURIComponent(pSetting2.paramValue));
              } else {
                impURL.searchParams.set(pSetting2.paramProperty, pSetting2.paramValue);
              }

            }
            else {
              if (pSetting2.paramProperty !== 'af_click_lookback') {
                impURL.searchParams.append(pSetting2.paramProperty, pSetting2.paramValue);
              }
              if (pSetting2.paramProperty === 'af_click_lookback' && this.state.Reengagement) {
                impURL.searchParams.append(pSetting2.paramProperty, pSetting2.paramValue);
              }
            }
          }
        });

        /*var af_viewthrough_lookback = impURL.searchParams.get('af_viewthrough_lookback');
        if (af_viewthrough_lookback) {
          var hours = af_viewthrough_lookback.split('h')[0];
          if(hours.length<1 || (hours.length>0 && parseInt(hours)>=24)){
            impURL.searchParams.set('af_viewthrough_lookback', "24h");
          }         
        }*/
        if (!impURL.searchParams.get('af_viewthrough_lookback')) {
          if (this.state.afViewthroughLookbackName && this.state.afViewthroughLookbackName.length > 0 && this.state.afViewthroughLookback && this.state.afViewthroughLookback.length > 0) {
            if (this.state.afViewthroughLookbackName !== 'af_viewthrough_lookback') {
              impURL.searchParams.set(this.state.afViewthroughLookbackName, this.state.afViewthroughLookback);
            }
            else {
              var afViewthroughLookback = this.state.afViewthroughLookback;
              var hours = afViewthroughLookback.split('h')[0];
              if (hours.length < 1 || (hours.length > 0 && parseInt(hours) >= 24)) {
                afViewthroughLookback = "24h";
              }
              impURL.searchParams.set(this.state.afViewthroughLookbackName, afViewthroughLookback);
            }
          }
        }

        if (impURL.searchParams.get('onehrts')) {
          impURL.searchParams.delete('onehrts')
        }
        if (impURL.searchParams.get('redirect') && this.state.unlockParametersPassword !== "4567") {
          impURL.searchParams.delete('redirect')
        }

        if (impURL.searchParams.get('af_viewthrough_lookback') && this.state.Reengagement) {
          impURL.searchParams.set('redirect', 'false');
          impURL.searchParams.delete('af_viewthrough_lookback')
        }

        impUrlEncoded = impURL.href ? this.replaceSpecChars(encodeURIComponent(this.replaceSpecChars(impURL.href))) : '';
        parameterSettings[idx].paramValue = impUrlEncoded;
        return (pSetting.paramProperty + "=" + impUrlEncoded);
      }

      if (trafficguardSelected && pSetting.paramProperty === "implnk$") {
        impUrlDecoded = pSetting.paramValue ? decodeURIComponent(pSetting.paramValue) : '';
        impURL = new URL(impUrlDecoded);
        //var pSettings = this.state.parameterSettings;

        if (!this.state.Clientside) {
          if (impURL.searchParams.get("csrds")) {
            impURL.searchParams.delete("csrds");
          }
        }

        if (impURL.searchParams.get("is_retargeting") && (this.state.Clientside || this.state.Probabilistic)) {
          impURL.searchParams.delete("is_retargeting");
        }
        if (impURL.searchParams.get("x_af_reengagement_window") && (this.state.Clientside || this.state.Probabilistic)) {
          impURL.searchParams.delete("x_af_reengagement_window");
        }

        if (impURL.searchParams.get("idfa") && this.state.Probabilistic) {
          impURL.searchParams.delete("idfa");
        }
        if (impURL.searchParams.get("aaid") && this.state.Probabilistic) {
          impURL.searchParams.delete("aaid");
        }

        if (impURL.searchParams.get("x_af_ua") && !this.state.Probabilistic) {
          impURL.searchParams.delete("x_af_ua");
        }
        if (impURL.searchParams.get("x_af_lang") && !this.state.Probabilistic) {
          impURL.searchParams.delete("x_af_lang");
        }

        //pSettings.forEach(pSetting2 => {
        impUrlParameterSettings.forEach(pSetting2 => {
          if (pSetting2.paramProperty !== "implnk$" && pSetting2.paramProperty !== 'install_callback' && !pSetting2.paramProperty.startsWith('event_callback') && pSetting2.paramProperty !== 'devidreq$') {

            if (pSetting2.paramProperty === "x_af_viewthrough_lookback") {
              var afViewthroughLookback = this.state.afViewthroughLookback;
              if (afViewthroughLookback) {
                var hours = afViewthroughLookback.split('h')[0];
                if (hours.length < 1 || (hours.length > 0 && parseInt(hours) >= 24)) {
                  //impURL.searchParams.set('af_viewthrough_lookback', "24h");
                  pSetting2.paramValue = "24h";
                }
                else {
                  pSetting2.paramValue = afViewthroughLookback;
                }
              }
            }

            if (impURL.searchParams.get(pSetting2.paramProperty)) {
              //Encoding exceptions for params x_af_dp & deep_link_value
              if (pSetting2.paramProperty === "x_af_dp" || pSetting2.paramProperty === "deep_link_value") {
                impURL.searchParams.set(pSetting2.paramProperty, decodeURIComponent(pSetting2.paramValue));
              } else {
                impURL.searchParams.set(pSetting2.paramProperty, pSetting2.paramValue);
              }

            }
            else {
              if (pSetting2.paramProperty !== 'click_lookback') {
                impURL.searchParams.append(pSetting2.paramProperty, pSetting2.paramValue);
              }
              if (pSetting2.paramProperty === 'click_lookback' && this.state.Reengagement) {
                impURL.searchParams.append(pSetting2.paramProperty, pSetting2.paramValue);
              }
            }
          }
        });

        /*var af_viewthrough_lookback = impURL.searchParams.get('af_viewthrough_lookback');
        if (af_viewthrough_lookback) {
          var hours = af_viewthrough_lookback.split('h')[0];
          if(hours.length<1 || (hours.length>0 && parseInt(hours)>=24)){
            impURL.searchParams.set('af_viewthrough_lookback', "24h");
          }         
        }*/
        if (!impURL.searchParams.get('x_af_viewthrough_lookback')) {
          if (this.state.afViewthroughLookbackName && this.state.afViewthroughLookbackName.length > 0 && this.state.afViewthroughLookback && this.state.afViewthroughLookback.length > 0) {
            if (this.state.afViewthroughLookbackName !== 'x_af_viewthrough_lookback') {
              impURL.searchParams.set(this.state.afViewthroughLookbackName, this.state.afViewthroughLookback);
            }
            else {
              var afViewthroughLookback = this.state.afViewthroughLookback;
              var hours = afViewthroughLookback.split('h')[0];
              if (hours.length < 1 || (hours.length > 0 && parseInt(hours) >= 24)) {
                afViewthroughLookback = "24h";
              }
              impURL.searchParams.set(this.state.afViewthroughLookbackName, afViewthroughLookback);
            }
          }
        }

        if (impURL.searchParams.get('onehrts')) {
          impURL.searchParams.delete('onehrts')
        }
        if (impURL.searchParams.get('redirect') && this.state.unlockParametersPassword !== "4567") {
          impURL.searchParams.delete('redirect')
        }

        if (impURL.searchParams.get('x_af_viewthrough_lookback') && this.state.Reengagement) {
          impURL.searchParams.set('redirect', 'false');
          impURL.searchParams.delete('x_af_viewthrough_lookback')
        }

        impUrlEncoded = impURL.href ? this.replaceSpecChars(encodeURIComponent(this.replaceSpecChars(impURL.href))) : '';
        parameterSettings[idx].paramValue = impUrlEncoded;
        return (pSetting.paramProperty + "=" + impUrlEncoded);
      }

      /* Branch */
      if (branchSelected && pSetting.paramProperty === "implnk$") {

        impUrlDecoded = pSetting.paramValue ? decodeURIComponent(pSetting.paramValue) : '';
        var impUrlQs = impUrlDecoded.split('?').length > 1 ? impUrlDecoded.split('?')[1] : '';
        var impUrlParams = impUrlQs.split('&').length > 0 ? impUrlQs.split('&') : [];

        impURL = new URL(impUrlDecoded);
        //const searchParams = new URLSearchParams(impURL.search);
        /*if (impURL.searchParams.get("%24s2s") && this.state.Clientside) {
          impURL.searchParams.delete("%24s2s");
        }*/
        /* if (this.state.Clientside) {
           for (const name of searchParams.keys()) {
             if (name === decodeURIComponent("%24s2s")) { //$s2s 
               searchParams.delete(name);
             }
           }
           impURL.search = '?' + searchParams.toString();
         }*/

        if (impURL.searchParams.get('onehrts')) {
          impURL.searchParams.delete('onehrts')
        }

        impUrlParameterSettings.forEach(pSetting2 => {
          /*if (pSetting2.paramProperty !== "implnk$" && pSetting2.paramProperty !== 'install_callback' && !pSetting2.paramProperty.startsWith('event_callback') && pSetting2.paramProperty !== 'devidreq$' ) {
            var decodedParamProperty = decodeURIComponent(pSetting2.paramProperty);
            if (impURL.searchParams.get(decodedParamProperty)) {
              impURL.searchParams.delete(decodedParamProperty);
              impURL.searchParams.set(pSetting2.paramProperty, pSetting2.paramValue);
            }
            else {
              impURL.searchParams.append(pSetting2.paramProperty, pSetting2.paramValue);
            }
          }*/

          if (pSetting2.paramProperty !== "implnk$" && pSetting2.paramProperty !== 'install_callback' && !pSetting2.paramProperty.startsWith('event_callback') && pSetting2.paramProperty !== 'devidreq$') {
            var paramExists = false;
            impUrlParams.forEach((urlParam, index, urlParamsArr) => {
              var urlParamName = urlParam && urlParam.split('=').length > 0 ? urlParam.split('=')[0] : '';

              if (this.state.Clientside && urlParamName === "%24s2s") {
                delete impUrlParams[index];
                paramExists = true;
              }
              else {
                if (urlParamName === pSetting2.paramProperty) {
                  impUrlParams[index] = pSetting2.paramProperty + "=" + pSetting2.paramValue;
                  paramExists = true;
                }
              }
            });

            if (!paramExists) {
              if (pSetting2 && pSetting2.paramProperty && pSetting2.paramProperty.length > 0) {
                impUrlParams.push(pSetting2.paramProperty + "=" + pSetting2.paramValue);
              }
            }
          }
        });



        //var impUrlEncoded = impURL.href ? this.replaceSpecChars(encodeURIComponent(this.replaceSpecChars(impURL.href))):'';
        var impUrlBase = impUrlDecoded.split('?').length > 0 ? impUrlDecoded.split('?')[0] : '';
        var impUrlParamsString = impUrlParams.length > 0 ? impUrlParams.join('&') : '';
        var impUrlHref = impUrlBase.length > 0 ? impUrlBase + '?' + impUrlParamsString : '';
        impUrlEncoded = impUrlHref ? this.replaceSpecChars(encodeURIComponent(this.replaceSpecChars(impUrlHref))) : '';
        parameterSettings[idx].paramValue = impUrlEncoded;
        return (pSetting.paramProperty + "=" + impUrlEncoded);
      }

      var paramValueEncoded;
      if (pSetting.paramProperty === 'install_callback' || pSetting.paramProperty.startsWith('event_callback') || pSetting.paramProperty === 'implnk$') {
        paramValueEncoded = this.replaceSpecChars(pSetting.paramValue);
      }
      else {
        paramValueEncoded = pSetting.paramValue !== null ? this.replaceSpecChars(encodeURIComponent(pSetting.paramValue)) : '';
      }

      return (pSetting.paramProperty + "=" + paramValueEncoded);
    });

    var genClickUrl = baseClickUrl + "?" + clickUrlParams.join('&');

    if (trackingProvider === APPSFLYER_ID || trackingProvider === TRAFFICGUARD_ID || trackingProvider === ADJUST_ID || trackingProvider === BRANCH_ID) {
      //if(genClickUrl.length>CLICKURL_MAX_LENGTH){
      var clickUrlParams2 = [];
      parameterSettings.forEach(pSetting => {
        if (pSetting.paramProperty === "implnk$") {
          var impressionUrlDecoded = this.replaceSpecChars(decodeURIComponent(pSetting.paramValue));
          description = "__IMPRESSION__=" + this.replaceSpecChars(encodeURIComponent(impressionUrlDecoded));
        } else {
          if (pSetting.paramProperty.startsWith('event_callback')) {
            clickUrlParams2.push(pSetting.paramProperty + "=" + this.replaceSpecChars(encodeURIComponent(pSetting.paramValue)));
          }
          else {
            clickUrlParams2.push(pSetting.paramProperty + "=" + pSetting.paramValue);
          }
        }
      });
      finalClickUrl = baseClickUrl + "?" + clickUrlParams2.join('&');
      //}
    }
    else {
      finalClickUrl = genClickUrl;
    }

    if (trackingProvider === ADJUST_ID) {
      if (this.state.Clientside) {
        //finalClickUrl = finalClickUrl.replaceAll('s2s.adjust.com', 'app.adjust.com');
        finalClickUrl = finalClickUrl.replaceAll('s2s.adjust.com', 'app.adjust.com');
        if (description !== null && description.length > 0) {
          description = description.replaceAll('s2s.adjust.com', 'view.adjust.com');
        }
      }
      else {
        if (impUrlExists) {
          finalClickUrl = finalClickUrl.replaceAll('app.adjust.com', 's2s.adjust.com');
          if (description !== null && description.length > 0) {
            description = description.replaceAll('view.adjust.com', 's2s.adjust.com');
          }
        }
      }

    }

    var clickUrlValidated = this.validateUrl(finalClickUrl);
    if (!clickUrlValidated.isValid) {
      this.setState({ busy: false, error: "Action was not completed: " + clickUrlValidated.message });
      return;
    } else {
      clickUrlValidated = this.specialChars_url(finalClickUrl);
      if (!clickUrlValidated.isValid) {
        this.setState({ busy: false, error: "Action was not completed: " + clickUrlValidated.message });
        return;
      }
    }

    /* Get save values for TrackingLinkComposer collection*/
    var trackingProviderName = null;
    initTrackingProvidersList().forEach((item, idx) => {
      if (item.id === trackingProvider) trackingProviderName = item.description;
    });

    var linkType = {
      passthrough: this.state.Passthrough || false,
      clientside: this.state.Clientside || false,
      probabilistic: this.state.Probabilistic || false,
      reengagement: this.state.Reengagement || false
    };

    var impressionUrl = '';
    if (description && description.length > 0) {
      var urlParts = description.split("__IMPRESSION__=");
      impressionUrl = decodeURIComponent(urlParts[1]);
    }
    var clickUrlParsed = finalClickUrl.length > 0 ? new URL(finalClickUrl) : null;
    var impressionUrlParsed = impressionUrl.length > 0 ? new URL(impressionUrl) : null;

    var clickurlObj = null;
    var impressionurlObj = null;

    const clickSearchParams = new URLSearchParams(clickUrlParsed.search);
    const impSearchParams = new URLSearchParams(impressionUrlParsed ? impressionUrlParsed.search : null);

    if (clickUrlParsed && clickSearchParams) {
      clickurlObj = {};
      for (const name of clickSearchParams.keys()) {
        if (name !== null) {
          var clickParamValue = clickSearchParams.get(name);
          clickurlObj[name] = clickParamValue !== null && clickParamValue.length > 0 ? clickParamValue : '';
        }
      }
    }

    if (impressionUrlParsed && impSearchParams) {
      impressionurlObj = {};
      for (const name of impSearchParams.keys()) {
        if (name !== null) {
          var impParamValue = impSearchParams.get(name);
          impressionurlObj[name] = impParamValue !== null && impParamValue.length > 0 ? impParamValue : '';
        }
      }
    }


    /*var clickurlParams = {
      af_siteid: clickUrlParsed?clickUrlParsed.searchParams.get('af_siteid'):'',
      af_click_lookback: clickUrlParsed?clickUrlParsed.searchParams.get('af_click_lookback'):'',
      af_viewthrough_lookback: clickUrlParsed?clickUrlParsed.searchParams.get('af_viewthrough_lookback'):'',
      af_reengagement_window: clickUrlParsed?clickUrlParsed.searchParams.get('af_reengagement_window'):''
    };
    var impressionurlParams = {
      af_siteid: impressionUrlParsed?impressionUrlParsed.searchParams.get('af_siteid'):'',
      af_click_lookback: impressionUrlParsed?impressionUrlParsed.searchParams.get('af_click_lookback'):'',
      af_viewthrough_lookback: impressionUrlParsed?impressionUrlParsed.searchParams.get('af_viewthrough_lookback'):'',
      af_reengagement_window: impressionUrlParsed?impressionUrlParsed.searchParams.get('af_reengagement_window'):''
    };*/
    /* End of get values */

    var postData = {
      CampaignId: campaignId,
      ClickUrl: finalClickUrl,
      PostbackUrl: decodeURIComponent(postbackUrl),
      TrackingProvider: trackingProviderName,
      TrackingProviderId: trackingProvider,
      LinkType: linkType,
      ClickurlParams: clickurlObj,
      ImpressionurlParams: impressionurlObj,
    }

    if (description !== null && description.length > 0) {
      postData.Description = description;
    }

    if (formIsValid) {
      axios({
        url: "/api/v1/tools/savecampaigntrackinglinktest?id=" + campaignId,
        method: "post",
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        responseType: "json",
        data: postData,
      })
        .then(res => {
          var msgList = [];
          var msg = null;

          if (res && res.data && res.data.length > 0) {
            (res.data).forEach((item, idx) => {
              if (item) {
                var campaignId = item.campaignId || "N/A";
                var clickUrl = item.clickUrl || "N/A";
                var postbackUrl = item.postbackUrl || "N/A";
                var description = item.description || "N/A";
                msgList.push(<span>Campaign with <strong>ID: {campaignId} - {this.state.campaignName}</strong> was saved successfully with the following values:</span>);
                msgList.push(<span><strong>Click URL: </strong>{clickUrl}</span>);
                msgList.push(<span><strong>Postback URL: </strong>{postbackUrl}</span>);
                if (postData.hasOwnProperty("Description")) {
                  msgList.push(<span><strong>Description: </strong>{description}</span>);
                }
              }
            });
          }
          if (msgList.length > 0) {
            msg = <ul>
              {msgList.map((x, i) => (
                <li style={(i === 0 ? { listStyle: 'none', marginLeft: '-15px' } : { wordBreak: 'break-all' })} key={i}>{x}</li>
              ))}
            </ul>;
          }

          this.tableRef.current.reload();
          this.handleCancelClick();
          this.setState({
            msg: msg,
            error: null,
            busy: false
          });
          return;
        })
        .catch(err => {
          const error = err.response
            ? err.response.data
            : err.request
              ? err.request
              : err.message;

          this.setState({
            error,
            busy: false
          });

        });
    }
    else {
      this.setState({
        msg: null,
        error: "Please enter valid url parameter values.",
        busy: false
      });
    }

  }

}